import React from "react";
import styled from "@emotion/styled";
import DateSelector from "../components/UpDownSelector/DateSelector";
import { projectDueData } from "../data/titleData";
import { useRecoilValue } from "recoil";
import { selectors } from "../recoil/atom";
import classNames from "classnames";
import ChoiseChip from "../components/Chip/ChoiseChip";

const StyledContainer = styled.div`
    .style-wrap {
        .title {
            .no-touch-screen & {
                @media screen and (max-height: 650px) {
                    margin-bottom: 36px;
                }
            }
        }

        .input-container {
            width: 100%;
            position: relative;

            @media screen and (max-width: 1055px) {
                top: -26px;
                max-width: 570px;
            }

            .divider {
                display: block;
                margin: 30px 0;

                height: 2px;
                width: 100%;
                background: rgba(0, 0, 0, 0.05);

                .current & {
                    box-shadow: 0px 2px 2px rgba(255, 255, 255, 0.32);
                }

                @media screen and (max-width: 1055px) {
                    margin: 17px 0;
                }
            }
        }

        .choise-chip {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 50px 0 0;
            @media screen and (max-width: 1055px) {
                padding: 80px 0 0;
            }
            .chip-container {
                &.choise {
                    pointer-events: none;
                }
                display: flex;
                align-items: center;

                margin: 0;

                &.small-chip {
                    margin: 0;

                    & > div {
                        opacity: 0;
                        visibility: hidden;

                        .chip {
                            font-size: 0.54vw;

                            @media screen and (max-width: 1980px) {
                                font-size: 11px;
                            }
                        }
                    }
                }

                @media screen and (max-width: 1055px) {
                    margin: 0;
                }

                .choise-text {
                    font-size: 0.7vw;
                    font-weight: 400;
                    line-height: 1.5;
                    white-space: nowrap;

                    word-break: keep-all;
                    word-wrap: break-word;

                    opacity: 0;
                    visibility: hidden;


                    @media screen and (max-width: 1980px) {
                        font-size: 14px;
                    }

                    @media screen and (max-width: 1055px) {
                        font-size: 12px;
                        letter-spacing: -0.04em;
                        line-height: 1.2;
                    }
                }

                .divider-container {
                    width: calc(100% - 440px);
                    margin: 0 25px;
                    @media screen and (max-width: 1055px) {
                        flex: 1;
                    }
                    .divider {
                        display: block;
                        width: 100%;
                        height: 1px;

                        background: #000;

                        transform-origin: right;
                    }
                }
            }
            & > .chip-container {
            width: 100%;
                @media screen and (max-width: 1055px) {
                    position: absolute;
                    width: 100%;
                    right: 0%;
                    bottom: 0;
                    justify-content: space-between;
                    trsnsform: none;
                }
            }
        }
    }
`;

const ProjectDueView = () => {
    const lang = useRecoilValue(selectors["getLanguage"]);
    const { title } = projectDueData[lang];
    return (
        <>
            <StyledContainer className="style-container" data-step="3">
                <div className="style-wrap">
                    <div className="title">
                        <div className="step">
                            <span className="current">3</span>
                            <span className="line"></span>
                            <span className="total">6</span>
                        </div>
                        <div>{title}</div>
                    </div>
                    <div className="input-container">
                        <DateSelector coilKey="startDue" text="Start Date." />
                        <span className="divider"></span>
                        <DateSelector coilKey="endDue" text="End Date." />

                        <div className="choise-chip">
                        <div
                            className={classNames("chip-container", "choise")}
                        >
                            <span className="choise-text">Plus X 일정에 맞추어<br className="no-pc" /> 일정을 조율할 수 있나요?</span>
                            <div className="divider-container">
                                <span className="divider"></span>
                            </div>
                            <ChoiseChip
                                coilKey="scheduleAdjust"
                                choises={{
                                    yes: {
                                        text: { en: "Yes", kr: "Yes" },
                                        id: "can-adjust",
                                    },
                                    no: {
                                        text: { en: "No", kr: "No" },
                                        id: "cant-adjust",
                                    },
                                }}
                                small
                            />
                        </div>
        
                    </div>
                    </div>
                </div>
            </StyledContainer>
        </>
    );
};

export default React.memo(ProjectDueView);
