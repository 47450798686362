import React, { useCallback, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import Header from "../Header";
import StepContainer from "../StepContainer";
import { useRecoilState, useRecoilValue } from "recoil";
import { atoms, formatMonth, selectors } from "../../recoil/atom";
import Views from "../../views";
import gsap from "gsap";
import ModalView from "../../views/ModalView";
import Progress from "../Progress";
import Alert from "../Alert";
import LineView from "../../views/LineView";
import { theme } from "../../theme";
import AnimationWrapper from "../AnimationWrapper";
import Loading from "../Loading";
import useReset from "../../hooks/useReset";
import useTab from "../../hooks/useTab";


import MainView from "../../views/MainView";
import RequestTypeView from "../../views/RequestTypeView";
import RequesterView from "../../views/RequesterView";

import AdditionalInfoView from "../../views/AdditionalInfoView";
import CheckPrivacyView from "../../views/CheckPrivacyView";
import CheckInformationView from "../../views/CheckInformationView";
import SendFormView from "../../views/SendFormView";
import FinalView from "../../views/FinalView";

const AppContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100dvh;
    overflow-y: auto;
   
    .no-touch-screen & {
        min-height: 780px;
    }
    .touch-screen & {
        min-height: unset;
    }
    .style-container {
        width: 100vw;
        height: 100%;
        flex-shrink: 0;
        overflow-x: hidden;

        position: absolute;
        z-index: -1;

        display: none;

        &.active {
            z-index: 1 !important;
        }

        @media screen and (max-width: 1055px) {
            padding: 0;
        }
    }

    .style-wrap {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;

        justify-content: center;

        padding: 0
            ${theme["normal"].paddingHorizontal.value * 2 +
            theme["normal"].paddingHorizontal.unit};

        @media screen and (max-width: 1300px) {
            padding: 0 4.2vw;
        }

        @media screen and (max-width: 1055px) {
            flex-direction: column;
            justify-content: flex-start;

            padding: 25px 25px 20px;
            overflow-x: hidden;

        }

        .no-touch-sreen & {
            overflow-y: hidden;
        }

        @media screen and (max-width: 375px) {
            padding-top: 15px;
        }

        .title {
            position: relative;
            font-size: clamp(24px, 3.38vw, 65px);
            font-weight: 700;
            line-height: 1.28;

            padding-right: 0.45em;
            white-space: nowrap;

            flex: 1;
            opacity: 0;
            visibility: hidden;

            will-change: transform, opacity;

            @media screen and (max-width: 1500px) {
                flex: 2;
            }
            @media screen and (max-width: 1300px) {
                flex: 1;
            }

            @media screen and (max-width: 1055px) {
                align-self: flex-start;

                flex: 0 0 auto;

                .no-touch-screen & {
                    height: max-content;
                }

                .touch-screen & {
                }

                margin-bottom: 40px;
            }

            .dark-mode & {
                color: #fff;
            }

            .step {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                font-size: 14px;
                margin-bottom: 20px;
                span {
                    min-width: 11px;
                    text-align: center;
                    font-family: 'Roboto';
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 20px;
                    @media screen and (max-width: 1055px) {
                        min-width: 7px;
                        font-size: 12px;
                    }
                }
                .total {
                    color: #999;
                }
                .current {
                }
                .line {
                    width: 16px;
                    height: 2px;
                    background-color: #999;
                    @media screen and (max-width: 1055px) {
                        width: 14px;
                    }
                }
                @media screen and (max-width: 1055px) {
                    gap: 6px;
                    margin-bottom: 10px;
                }
            }

            .sub-text {
                color: #999;
                font-size: 16px;
                line-height: 1.5;
                margin-top: 20px;
                font-weight: 400;

                @media screen and (max-width: 1055px) {
                    font-size: 13px;
                    line-height: 20px;
                    margin-top: 10px;
                }
            }
        }

        .input-container {
            flex: 1;
            opacity: 0;
            visibility: hidden;

            will-change: transform, opacity;

            @media screen and (max-width: 1500px) {
                flex: 3;
            }
            @media screen and (max-width: 1300px) {
                flex: 1;
            }

            @media screen and (max-width: 1055px) {
                flex: 0;
            }
        }
    }
`;

const ContentContainer = styled.div`
    position: absolute;
    width: 100%;

    margin-top: 90px;
    z-index: 501;
    min-height: calc(100% - 220px);
    height: calc(100% - 220px);
    margin-top: 40px;
    &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 6px;
        margin-right: 60px;
    }

    &::-webkit-scrollbar-track {
        margin-right: 60px;
    }

    &:not(:has(.active)) {
        &::-webkit-scrollbar {
            display: none 
        }
    }
    @media screen and (max-width: 1055px) {
        min-height: calc(100% - 150px);
        height: calc(100% - 150px);

        .touch-screen & {
            overflow-y: auto;
        }
    }
`;

const ContentWrapper = styled.div`
    height: 100%;
    position: relative;

    display: flex;
    flex-wrap: nowrap;
`;

const App = () => {
    /**
     * * 변수 정보
     */

    // 상태 초기화 hook 관련 변수
    const { resetAll: dataReset } = useReset();

    // Recoil에서 모바일 넓이 체크 및 설정
    const [isMobile, setIsMobile] = useRecoilState(atoms["mobileWidth"]);

    // Recoil에서 언어 체크 및 설정
    const [lang, setLang] = useRecoilState(atoms["language"]);

    // 의뢰 종류에 맞는 슬라이드 렌더링
    const requestType = useRecoilValue(selectors["getRequestType"]);
    const requestTypeIndex = requestType !== "etc" ? 0 : 1;
    const ContentView = requestType !== "etc" ? Views[0] : Views[1];

    // 현재 보여지고 있는 슬라이드 인덱스
    const currentActive = useRecoilValue(selectors["getCurrentActive"]);

    // Tab hook 관련 변수
    const { tabControl, setTab, addTabFrom } = useTab(currentActive);
    useEffect(() => {
        setTab(0);
    }, [setTab, currentActive]);

    useEffect(() => {
        const swiper = document.querySelector(".swiper-wrapper");

        window.onkeydown = function (e) {
            if (e.keyCode === 9 || e.keyCode === 13) {
                if (e.keyCode === 13 && e.target.tagName === "TEXTAREA") {
                    return;
                }
                // Tab
                e.preventDefault();

                const currentActive = swiper.dataset.active * 1;
                const currentType = swiper.dataset.type * 1;

                if (!e.shiftKey || isMobile) {
                    addTabFrom(1);
                } else if (e.shiftKey) {
                    addTabFrom(-1);
                }

                tabControl[currentType][currentActive]();
            }
        };
    }, [tabControl, addTabFrom, isMobile]);
    // 모바일 이슈들
    const initialHeight = useRef(window.innerHeight);
    const isAndroid = /Android/.test(navigator.userAgent);
    const needVerticalAlign = /^((?!chrome|android).)*(safari|firefox)/i.test(
        navigator.userAgent
    );

    useEffect(() => {
        const scrollHandler = () => {
            gsap.set([".view-container", ".header-container"], {
                y: `-${window.scrollY}px`,
            });
        };

        if (isAndroid) {
            // Android 키보드 position fixed 스크롤 이슈
            gsap.set([".view-container", ".header-container"], {
                y: `-${0}px`,
            });
            window.addEventListener("scroll", scrollHandler);
        }

        const resizeHandler = () => {
            // Android 키보드 밀림 현상 이슈
            // document.documentElement.style.setProperty("overflow", "auto");
            const metaViewport = document.querySelector("meta[name=viewport]");
            metaViewport.setAttribute(
                "content",
                "height=" +
                    initialHeight.current +
                    "px, width=device-width, initial-scale=1.0"
            );
        };
        if (isAndroid) {
            window.visualViewport.addEventListener("resize", resizeHandler);
        }
        if (isAndroid) {
            document.querySelector(".view-container").style.position = "fixed";
        }

        if (needVerticalAlign) {
            document.body.classList.add("need-vertical-align");
        } else {
            document.body.classList.remove("need-vertical-align");
        }

        return () => {
            window.removeEventListener("scroll", scrollHandler);
            window.removeEventListener("resize", resizeHandler);
        };
    }, [isAndroid, needVerticalAlign]);

    useEffect(() => {
        if (requestType !== -1) {
            dataReset(requestType);
        }
    }, [requestType, dataReset]);

    useEffect(() => {
        if ("ontouchstart" in document.documentElement) {
            document.body.classList.add("touch-screen");
            document.body.classList.remove("no-touch-screen");
        } else {
            document.body.classList.remove("touch-screen");
            document.body.classList.add("no-touch-screen");
        }
    }, [isMobile]);

    useEffect(() => {
        // 모바일 화면인지 체크
        let prevSize = window.innerWidth;

        const resizeHandler = function () {
            if (this.innerWidth <= 1055 && prevSize > 1055) {
                // Mobile width
                setIsMobile(true);
            } else if (this.innerWidth > 1055 && prevSize <= 1055) {
                // PC width
                setIsMobile(false);
            }
            prevSize = this.innerWidth;
        };

        // window.addEventListener("resize", resizeHandler);

        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, [setIsMobile]);

    // 언어 변경
    const handleLanguage = (e) => {
        if (e.target.classList.contains("lang-kr")) {
            setLang("kr");
        }
        if (e.target.classList.contains("lang-en")) {
            setLang("en");
        }
    };

    useEffect(() => {
        document.documentElement.lang = lang;
    }, [lang]);

    const decideRender = useCallback(
        (index) => {
            if (requestTypeIndex === 1) {
                // 기타 의뢰
                if (currentActive >= index) {
                    return true;
                }
            } else {
                // 프로젝트 의뢰
                if (currentActive >= index + 1) {
                    return true;
                }
            }

            return false;
        },
        [requestTypeIndex, currentActive]
    );

    return (
        <AppContainer className="app-container">
            {/* 로딩 딤 */}
            <Loading />
            {/* 헤더 */}
            <Header handleLanguage={handleLanguage} />
            {/* 메세지 창 */}
            <Alert />
            {/* 컨텐츠 */}
            <ContentContainer className="view-container">
                <LineView />
                <ContentWrapper
                    className="swiper-wrapper"
                    data-active="0"
                    data-type={requestTypeIndex}
                >
                    <MainView />
                    <RequestTypeView />
                    <ContentView />
                    <RequesterView type={requestTypeIndex} />
                    <AdditionalInfoView type={requestTypeIndex} />
                    <CheckInformationView type={requestTypeIndex} />
                    {/* <SendFormView /> */}
                    <FinalView />
                    <AnimationWrapper render={decideRender(7)}>
                        
                    </AnimationWrapper>
                 
                    <AnimationWrapper render={decideRender(7)}>
             
                    </AnimationWrapper>
                    <AnimationWrapper render={decideRender(8)}>
                        
                    </AnimationWrapper>
                </ContentWrapper>
            </ContentContainer>
            {/* 하단 버튼 */}
            <StepContainer requestType={requestTypeIndex} />
            {/* Progress bar */}
            <Progress />
            {/* Modal */}
            <ModalView />
        </AppContainer>
    );
};

export default App;
