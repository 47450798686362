import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import StepManager from "../Button/StepManager";
import classNames from "classnames";
import ChoiseChip from "../Chip/ChoiseChip";
import { theme } from "../../theme";
import { useRecoilValue } from "recoil";
import { selectors } from "../../recoil/atom";
import useReset from "../../hooks/useReset";
import useValidation from "../../hooks/useValidation";
import useAnimation from "../../hooks/useAnimation";

const StyledStepContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: absolute;
    bottom: 0;
    z-index: 2000;

    width: 100vw;
    padding: 0
        ${theme["normal"].paddingHorizontal.value +
        theme["normal"].paddingHorizontal.unit}
        ${theme["normal"].paddingVertical.value +
        theme["normal"].paddingVertical.unit};

    opacity: 0;
    visibility: hidden;

    user-select: none;

    @media screen and (max-width: 1055px) {
        padding: 0
            ${theme["1055"].paddingHorizontal.value +
            theme["1055"].paddingHorizontal.unit}
            ${theme["1055"].paddingVertical.value +
            theme["1055"].paddingVertical.unit};
    }

    &.current {
        z-index: 502;
    }

    &.current .chip-container.choise {
        pointer-events: auto;
    }

    .home-container {
        display: none;
        opacity: 0;
    }


`;

const StepContainer = ({ requestType }) => {
    const disable = useRecoilValue(selectors["getNextDisabled"]);
    const isSlideAnimating = useRecoilValue(selectors["getIsSlideAnimating"]);
    const apiLoading = useRecoilValue(selectors["getApiLoading"]);
    const apiSuccess = useRecoilValue(selectors["getApiSuccess"]);

    const [shadow, setShadow] = useState(false);

    const { nextActiveCondition } = useValidation();
    const { toHome } = useReset();
    const { next, back } = useAnimation();

    const [ activeStep, setActiveStep ] = useState(0);

    // 유효성 체크
    useEffect(() => {
        const swiper = document.querySelector(".swiper-wrapper");
        const active = swiper.dataset.active * 1;
        const type = swiper.dataset.type * 1;

        nextActiveCondition[type][active]();
    }, [nextActiveCondition]);

    useEffect(() => {
        const swiper = document.querySelector(".swiper-wrapper");
        const active = swiper.dataset.active * 1;
        const type = swiper.dataset.type * 1;

        if (!isSlideAnimating) {
            if (type === 0 && active === 6) {
                setShadow(true);
            }
        } else {
            setShadow(false);
        }

        setActiveStep(active);

    }, [isSlideAnimating]);

    const handleActive = (swiper, type) => {
        if (!isSlideAnimating) {
            const active = swiper.dataset.active * 1;
            const requestType = swiper.dataset.type * 1;

            console.log({active, requestType})

            if (type !== 2) {
                nextActiveCondition[requestType][active]();
            } else {
                // Home
                if (requestType === 0) {
                    toHome(8);
                } else {
                    toHome(6);   
                }
                nextActiveCondition[0][0]();
            }
        }
    };

    return (
        <StyledStepContainer
            className={classNames("step-container", shadow && "current")}
        >
            <StepManager
                type={0}
                onClick={handleActive}
                back={back}
                loading={apiLoading || apiSuccess}
                requestType={requestType}
            />
            <StepManager
                type={1}
                disabled={disable}
                onClick={handleActive}
                next={next}
                activeStep={activeStep}
                requestType={requestType}
            />
            <StepManager type={2} onClick={handleActive} />
        </StyledStepContainer>
    );
};

export default StepContainer;
