import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import classNames from "classnames";
import { gsap } from "gsap";
import { useRecoilValue, useRecoilState } from "recoil";
import { atoms, selectors } from "../../recoil/atom";
import { theme } from "../../theme";
import { sendViewData } from "../../data/titleData";
import usePost from "../../hooks/usePost";
import useAlert from "../../hooks/useAlert";
import CheckButton, { hidePoint } from "../Button/CheckButton";
const mobilePadding = 12;

const transitionInDurPc = 0.3;
const transitionInDurMobile = 0.3;
const transitionInEase = "cubic-bezier(0.45, 0, 0.55, 1)";

const transitionOutDurPc = 0.5;
const transitionOutDurMobile = 0.4;
const transitionOutEase = "cubic-bezier(0.33, 1, 0.68, 1)";
const StyledLoading = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    z-index: 2000;
    transform: none;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    .ing-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
        opacity: 0;
        z-index: 1;
    }
    .ing-line {
        display: flex;
        align-items: center;
        position: relative;
        overflow: visibile;
        gap: 6px;
        padding: 10px 0;
        z-index: 2;
    }
    .ing-char {
        position: relative;
        display: inline-block;
        width: 14px;
        height: 14px;
        transform: translate(0, 100%);
        font-size: 0;

        opacity: 0;
        will-change: transform, opacity;
        background-color: #ff0000;
        border-radius: 50%;

        @media screen and (max-width: 1055px) {
            font-size: 1.8em;
        }
    }
`
const StyledContainer = styled.div`
    font-size: 40px;
    border-radius: 4.5em;
    position: relative;
    display: flex;

    user-select: none;

    @media screen and (max-width: 1500px) {
        font-size: 36px;
    }

    @media screen and (max-width: 1055px) {
        right: -${mobilePadding}px;
        bottom: -${mobilePadding}px;
    }

    &.back-container {
        @media screen and (max-width: 1055px) {
            right: unset;
            left: -${mobilePadding}px;
        }
    }

    .border-clone {
        position: absolute;
        width: calc(100% - 2px);
        height: calc(100% - 2px);

        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        font-size: 40px;
        border-radius: 4.25em;
        border-width: 5px;
        border-style: solid;
        border-color: #000;

        pointer-events: none;

        transition-property: border-color;
        transition-duration: ${transitionOutDurPc}s;
        transition-timing-function: ${transitionOutEase};

        z-index: 1;

        .dark-mode & {
            border-color: #fff;
        }

        @media screen and (max-width: 1055px) {
            transition-duration: ${transitionOutDurMobile}s;

            border-width: 4px;
            font-size: 26px;

            width: calc(100% - 24px);
            height: calc(100% - 24px);

            border-radius: 170px;
        }
    }

    .disabled ~ .border-clone {
        transition-duration: ${transitionInDurPc}s;
        transition-timing-function: ${transitionInEase};
        @media screen and (max-width: 1055px) {
            transition-duration: ${transitionInDurMobile}s;
        }

        border-color: #ccc;

        .dark-mode & {
            border-color: #333;
        }
    }

    button:not(.disabled):active ~ .border-clone {
        border-color: #222;
        .dark-mode & {
            border-color: #eee;
        }
    }

    button {
        position: relative;

        outline: none;
        background: transparent;
        border: none;

        .text {
            display: block;

            border-width: 4px;
            border-style: solid;
            border-color: #000;
            border-radius: 4.25em;
            padding: 0.5em 0.8em;

            pointer-events: none;

            user-select: none;

            position: relative;
            overflow: hidden;
            isolation: isolate;
            transition-property: color, border-color;
            transition-duration: ${transitionOutDurPc}s;
            transition-timing-function: ${transitionOutEase};
            white-space: nowrap;

            @media screen and (max-width: 1055px) {
                transition-duration: ${transitionOutDurMobile}s;
            }
        }

        ${"" /* Safari text vertical align issue */}
        .need-vertical-align & {
            .inner-text {
                display: inline;
                vertical-align: 0.1em;
            }

            .before-text {
                position: absolute;
                top: 50%;
                transform: translate(0, calc(-50% - 0.06em));
            }
        }

        ${"" /* End Safari text vertical align issue*/}

        @media screen and (max-width: 1055px) {
            padding: ${mobilePadding}px;
        }

        font-size: inherit;
        font-weight: 700;
        line-height: 1.1;

        text-transform: uppercase;

        cursor: pointer;

        &.back {
            display: none;
            opacity: 0;
        }

        .dark-mode & .text {
            border-color: #fff;
            color: #fff;
        }

        @media screen and (max-width: 1055px) {
            .text {
                border-width: 2px;
                font-size: clamp(20px, 6.93vw, 26px);
                padding: 6px clamp(36px, 9.6vw, 47px);
                line-height: normal;
                border-radius: 170px;
            }
        }
            
        .before-text,
        .inner-text {
            display: block;
            transform: translateY(-1px);
        }
        & .before {
            position: absolute;
            top: -3px;
            right: -3px;
            width: calc(100% + 6px);
            height: calc(100% + 6px);

            color: #fff;
            pointer-events: none;

            user-select: none;

            .dark-mode & {
                color: #000;
            }

            display: flex;
            justify-content: center;
            align-items: center;

            clip-path: polygon(-100% 0%, 0% 0%, 0% 100%, -100% 100%);
            @media screen and (max-width: 1055px) {
                will-change: clip-path;
            }
        }

        & .after {
            position: absolute;
            top: -3px;
            right: -3px;

            width: calc(100% + 6px);
            height: calc(100% + 6px);

            background: #000;
            color: #fff;

            display: flex;
            justify-content: center;
            align-items: center;

            transform: translate(-100%, 0);

            transition-property: border-color, background-color, color;
            transition-duration: ${transitionOutDurPc}s;
            transition-timing-function: ${transitionOutEase};

            @media screen and (max-width: 1055px) {
                will-change: transform;

                transition-duration: ${transitionOutDurMobile}s;
            }

            .dark-mode & {
                background: #fff;
                color: #000;
            }
        }

        &.disabled {
            cursor: initial;
        }

        &.disabled .text,
        &.disabled .after,
        &.disabled .before {
            cursor: initial;
            border-color: #ccc;

            background: transparent;
            color: #ccc;

            &.text,
            &.after {
                transition-duration: ${transitionInDurPc}s;
                transition-timing-function: ${transitionInEase};
                @media screen and (max-width: 1055px) {
                    transition-duration: ${transitionInDurMobile}s;
                }
            }

            .dark-mode & {
                border-color: #333;
                color: #333;
            }

            &.after {
                background: #ccc;

                .dark-mode & {
                    background: #333;
                }
            }

            &.before {
                color: #f0f1f4;
                .dark-mode & {
                    color: #000;
                }
            }
        }

        &:not(.disabled):active .after {
            background: #222;

            .dark-mode & {
                background: #eee;
            }
        }

        &:not(.disabled):active .text {
            border-color: #222;
            .dark-mode & {
                border-color: #eee;
            }
        }
    }

    &.loading {
        button {
            color: #333 !important;
            border-color: #333 !important;
            .text {
                color: #333 !important;
                border-color: #333 !important;
            }

            & .after {
                background: #333 !important;
                border-color: #333 !important;
            }

            & .before {
                color: #333 !important;
            }
        }

        .border-clone {
            border-color: #333 !important;
        }
    }
`;

const StepManager = ({ type, disabled, onClick, back, next, loading, activeStep, requestType }) => {
    const isSlideAnimating = useRecoilValue(selectors["getIsSlideAnimating"]);
    const isMobile = useRecoilValue(selectors["getIsMobileWidth"]);
    const [, setIsSlideAnimating] = useRecoilState(atoms["isSlideAnimating"]);
    const [, setNextDisable] = useRecoilState(atoms["nextDisabled"]);
    const typeMap = ["back", "next", "home"];
    const typeLabel = ["이전", "다음", "홈으로"];
    const btn = useRef(null);
    const text = useRef(null);
    const after = useRef(null);
    const before = useRef(null);

    const [buttonText, setButtonText] = useState(typeLabel[type]);

    const [entered, setEntered] = useState(false);
    const [canClick, setCanClick] = useState(true);
    const [posted, setPosted] = useState(false);

    const isTouchScreen = "ontouchstart" in document.documentElement;

    // post
    const { sendPost } = usePost();
    const { toastAlert } = useAlert();

    const apiLoading = useRecoilValue(selectors["getApiLoading"]);
    const apiError = useRecoilValue(selectors["getApiError"]);
    const apiSuccess = useRecoilValue(selectors["getApiSuccess"]);
    const lang = useRecoilValue(selectors["getLanguage"]);
    const loadingRef = useRef(null);
    const { title, smallText, basicErrorText, phoneErrorText } =
        sendViewData[lang];

    const [points, setPoints] = useState([]);
    const [stopped, setStopped] = useState(false);
    useEffect(() => {
        // console.log({activeStep, type})
        // const swiperWrapper = document.querySelector(".swiper-wrapper");
        // const currentActive = swiperWrapper.dataset.active * 1;
        // console.log({currentActive , type})
        // if (currentActive === 7 && type === 1) {
            
        // }

        if (activeStep == 1 && type === 1) {
            setButtonText('시작')

        } else if (posted && type === 1) {
            setButtonText('제출');
        } else {
            if (requestType === 0) {

                if (activeStep === 7 && type === 1 && !isSlideAnimating)  {
                    setButtonText('제출');
                } else {
                    setButtonText(typeLabel[type])
                }
            } else {
    
                if (activeStep === 5 && type === 1 && !isSlideAnimating)  {
                    setButtonText('제출');
                } else {
                    setButtonText(typeLabel[type])
                }
            }

        }

    }, [activeStep, posted, isSlideAnimating])
    const _handleStep = (e) => {
        if (btn.current.classList.contains("disabled") || !canClick) {
            e.preventDefault();
            return;
        }

        const swiperWrapper = document.querySelector(".swiper-wrapper");
        const currentActive = swiperWrapper.dataset.active * 1;

        // console.log('_handleStep ', {activeStep, currentActive, type, stopped, apiLoading, isSlideAnimating})
        if (requestType === 0 && currentActive === 7 && type === 1) {
            if (apiLoading) {
                return;
            }
            sendPost()
            setStopped(true)
            
            setPosted(true)
            // return;
        } else if (requestType === 1 && activeStep === 5 && type === 1) {
            // console.log('이거',apiLoading)
            if (apiLoading) {
                return;
            }
            sendPost()
            setStopped(true)
            setPosted(true)
            // return;
        } else {
            if (apiLoading) return;
            const isMoving = swiperWrapper.dataset.moving;
            if (!isMoving && !isSlideAnimating) {
                if (type === 0 && currentActive > 0) {
                    // Back
                    back();
                    // console.log('back')
                } else if (type === 1) {
                    // Next
                    next();

                    // console.log('next')
                }
            }
            onClick(swiperWrapper, type);
        }

        

    };



    const moveNext = useCallback(() => {
        document.body.classList.remove("dark-mode");
        document.body.classList.add("final-view");
        const tl = gsap.timeline();
        tl.to(".next-container", {
            opacity: 0,
            ease: "power1.inOut",
            duration: theme.buttonOpDur,
        })
        tl.to(".back-container", {
            opacity: 0,
            ease: "power1.inOut",
            duration: theme.buttonOpDur,
        })
            .set(
                ".step-container",
                {
                    justifyContent: "flex-end",
                },
                ">+.2"
            )
            .set(
                ".back-container",
                {
                    display: "none",
                },
                ">+.1"
            )
            .set(
                ".loading-container",
                {
                    zIndex: -1,
                },
                ">"
            );
        const tl2 = gsap.timeline();

        tl2.delay(
            theme.bigButtonDelay +
                (isMobile
                    ? theme.stepButtonDelay.mobile
                    : theme.stepButtonDelay.pc) +
                1.8
        )
            .set(".home-container", {
                display: "flex",
            })
            .set(".step-container", {
                justifyContent: "flex-end",
            })
            .to(".home-container", {
                opacity: 1,
                ease: "power1.inOut",
                duration: theme.buttonOpDur,
            });

        const loadingFinish = document.querySelector(".loading-finished");

        if (loadingFinish) {
            document.body.classList.remove("loading-finished");
        }

        next();
    }, [next, isMobile]);

    useEffect(() => {
        if (apiSuccess) {
            document.body.classList.add("loading-finished");
        }
    }, [apiSuccess])


    useEffect(() => {
        if (type === 1) {
            if (apiError && posted && !apiLoading) {
                toastAlert(basicErrorText, () => {
                    hidePoint(isMobile, points);

                    setPoints([]);
                    setCanClick(true);
                    setNextDisable(false)
                    // console.log('is callback');
                    btn.current.classList.remove('disabled');

                });
            }
        }

    }, [
        apiError,
        apiLoading,
        toastAlert,
        basicErrorText,
        phoneErrorText,
        points,
        isMobile,
    ]);


    useEffect(() => {
        // console.log(apiSuccess , stopped)
        if (posted && apiSuccess && stopped) {
            setStopped(false);
        }
    }, [apiSuccess, moveNext, stopped]);

    const prevActive = useRef(1);

    const enterEvent = useCallback(() => {
        if (isTouchScreen && !canClick) {
            return;
        }

        if (!btn.current.classList.contains("disabled")) {
            const enterDur = isMobile ? 0.3 : 0.4;
            const enterEase = isMobile ? "" : "power2.out";

            const tl = gsap.timeline();
            gsap.killTweensOf(
                [after.current, before.current, text.current],
                "background, x, clipPath"
            );
            tl.addLabel("start")
                .to(
                    after.current,
                    {
                        x: "0",
                        duration: enterDur,
                        ease: enterEase,
                    },
                    "start"
                )
                .to(
                    before.current,
                    {
                        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                        duration: enterDur,
                        ease: enterEase,
                    },
                    "start"
                );

            return tl.totalDuration();
        }
    }, [isMobile, canClick, isTouchScreen]);

    const leaveEvent = useCallback(
        (force) => {
            if (
                (!btn.current.classList.contains("disabled") &&
                    !isTouchScreen) ||
                force
            ) {
                const leaveDur = isMobile ? 0.3 : 0.4;
                const leaveEase = isMobile ? "" : "power2.out";

                const tl = gsap.timeline();
                gsap.killTweensOf(
                    [after.current, before.current, text.current],
                    "background, x, clipPath"
                );

                tl.addLabel("start")
                    .to(
                        after.current,
                        {
                            x: "100%",
                            duration: leaveDur,
                            ease: leaveEase,
                        },
                        "start"
                    )
                    .to(
                        before.current,
                        {
                            clipPath:
                                "polygon(100% 0%, 200% 0%, 200% 100%, 100% 100%)",
                            duration: leaveDur,
                            ease: leaveEase,
                        },
                        "start"
                    )
                    .set(after.current, {
                        x: "-100%",
                    })
                    .set(before.current, {
                        clipPath:
                            "polygon(-100% 0%, 0% 0%, 0% 100%, -100% 100%)",
                    });
            }
        },
        [isMobile, isTouchScreen]
    );

    useEffect(() => {
        if (!isSlideAnimating && !isTouchScreen) {
            if (entered) {
                enterEvent();
            }
        }
    }, [isSlideAnimating]);

    useEffect(() => {
        if (isSlideAnimating) {
            setCanClick(false);
        } else {
            gsap.delayedCall(
                isMobile ? transitionInDurMobile : transitionInDurPc,
                () => {
                    setCanClick(true);
                }
            );
        }
    }, [isSlideAnimating, isMobile]);

    useEffect(() => {
        const swiper = document.querySelector(".swiper-wrapper");
        
        if (
            disabled &&
            swiper.dataset.active * 1 !== 0 &&
            swiper.dataset.active * 1 !== 1 &&
            swiper.dataset.active * 1 !== prevActive.current &&
            !isTouchScreen
        ) {
            leaveEvent(true);
        }

        const clickEvent = function (e) {
            if (btn.current.classList.contains("disabled")) {
                e.preventDefault();
                return;
            }
            // console.log(swiper.dataset.active, e)
            if (swiper.dataset.active === 7) {

            }
            // console.log({isTouchScreen, isSlideAnimating, canClick})
            if (isTouchScreen && !isSlideAnimating && canClick) {
                const enterDur = enterEvent();
                gsap.delayedCall(enterDur + 0.2, leaveEvent, [true]);
            } else if (!isTouchScreen && canClick) {
                leaveEvent(true);
            }
        };

        btn.current.addEventListener("click", clickEvent);

        const btnValue = btn.current;

        prevActive.current = swiper.dataset.active * 1;

        return () => {
            btnValue.removeEventListener("click", clickEvent);
        };
    }, [
        btn,
        disabled,
        canClick,
        onClick,
        type,
        isSlideAnimating,
        isMobile,
        isTouchScreen,
        leaveEvent,
        enterEvent,
    ]);

    useEffect(() => {
    }, [])

    const containerEnter = useCallback(() => {
        setEntered(true);
    }, []);

    const containerLeave = useCallback(() => {
        setEntered(false);
    }, []);
    useEffect(() => {
        if (!loadingRef.current || !posted) return;
        if (apiLoading && stopped) {
            const tl = gsap.timeline();

            const ing = loadingRef.current;
            const points = loadingRef.current.querySelectorAll(".ing-char");

            let stopCnt = 0;

            const jumpTo = "-40%";
            if (ing) {
                tl.set(ing, {
                    autoAlpha: 0,
                })
                    .set(
                        points,
                        {
                            y: "100%",
                            autoAlpha: 1,
                        },
                        ">"
                    )
                    .set(
                        ing,
                        {
                            autoAlpha: 1,
                        },
                        ">"
                    )
                    .addLabel("animationStart", ">")
                    .addLabel("start", "animationStart")
                    .to(
                        '.ing-bg',
                        {
                            autoAlpha: 1,
                            duration: 0.25,
                            ease: "power1.inOut",
                        },
                        "start"
                    )
                    .to(
                        points,
                        {
                            y: "0",
                            ease: "power2.in",
                            duration: 0.4,
                            stagger: {
                                each: 0.1,
                                onComplete() {
                                    const target = this.targets()[0];
                                    const index = target.dataset.index * 1;

                                    gsap.to(target, {
                                        y: jumpTo,
                                        yoyo: true,
                                        repeat: -1,
                                        duration: 0.4,
                                        ease: "power2.out",
                                        onComplete() {
                                    
                                            if (
                                                (document.body.classList.contains(
                                                    "loading-finished"
                                                ) ||
                                                    document.body.classList.contains(
                                                        "error-occured"
                                                    )) &&
                                                stopCnt === index
                                            ) {
                                                stopCnt++;
                                                tl.kill();
                                                if (
                                                    stopCnt === 3 &&
                                                    document.body.classList.contains(
                                                        "error-occured"
                                                    )
                                                ) {
                                                    document.body.classList.remove(
                                                        "error-occured"
                                                    );
                                                    setPoints(points);
                                                    return;
                                                } else if (stopCnt === 3) {
                                                    setStopped(true);
                                                }
                                            }
                                        },
                                    })
                                },
                            },
                        },
                        "start"
                    );
            }
        } else if (!apiLoading && !stopped && apiSuccess) {
            const tl = gsap.timeline({ delay: 1 });



            const ing = loadingRef.current;
            const points = loadingRef.current.querySelectorAll(".ing-char");
            // const left = -input.clientWidth;

            const xTo = isMobile ? "0" : "0vw";

            tl.addLabel("point", "privacyStart")
            .to(
                points,
                {
                    autoAlpha: 0,
                    duration: 0.5,
                    ease: "power1.inOut",
                    stagger: -0.07,
                    onComplete: () => {
                        gsap.delayedCall(0.2, () => {
           
                            gsap.to(points, { autoAlpha: 0 })
                            gsap.killTweensOf(points, 'all')
                            gsap.killTweensOf(ing, 'all')
                            gsap.set(points, { clearProps: 'all' })
                            gsap.set(ing, { clearProps: 'all' })


                            gsap.delayedCall(0.8, () => {
                                setPosted(false);
                            })

                        })
                 
                        window.onbeforeunload = null;
                    }
                },
                "point"
            )
            .to(
                '.ing-bg',
                {
                    autoAlpha: 0,
                    duration: 0.5,
                    ease: "power1.inOut",
                },
                "point"
            )
            .to(
                points,
                {
                    y: "100%",
                    duration: 0.3,
                    ease: "power2.in",
                    stagger: -0.07,
                    onComplete: () => {
                        gsap.delayedCall(0.3, () => {
                            moveNext();

                        })
                 
                    }
                },
                "point"
            )
            .addLabel("start", ">-=.2");
        }
    }, [apiLoading, setStopped, isMobile, setPoints, points]);

    return (
        <>
        <StyledContainer
            className={classNames(
                `${typeMap[type]}-container`,
                loading && "loading"
            )}
            onMouseEnter={containerEnter}
            onMouseOut={containerLeave}
        >
            <button
                className={classNames(
                    typeMap[type],
                    (disabled || isSlideAnimating || posted) && "disabled"
                )}
                onClick={_handleStep}
                data-text={typeMap[type]}
                onMouseEnter={enterEvent}
                onMouseLeave={leaveEvent.bind(null, false)}
                ref={btn}
            >
                <span className="text" ref={text}>
                    <span className="inner-text">{buttonText}</span>

                    <span className="after" ref={after}></span>
                    <span className="before" ref={before}>
                        <span className="before-text">{buttonText}</span>
                    </span>
                </span>
            </button>
            <div className="border-clone"></div>
        </StyledContainer>
        {type === 1 &&
            <StyledLoading className="ing" ref={loadingRef}>
                <div className="ing-bg" />
                <div className="ing-line">
                    <div className="ing-char" data-index="0" />
                    <div className="ing-char" data-index="1" />
                    <div className="ing-char" data-index="2" />
                </div>
            </StyledLoading>
        }
        </>
    );
};

export default StepManager;
