import styled from "@emotion/styled";
import React from "react";

const StyledContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 5px;

    user-select: none;

    .progress {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        background: #ff0000;

        transform-origin: left;
        transform: scaleX(0) translateZ(0);
        -webkit-transform: scaleX(0) translateZ(0);

        opacity: 0;
        visibility: hidden;
        will-change: transform;
    }
`;

const Progress = () => {
    return (
        <StyledContainer>
            <div className="progress"></div>
        </StyledContainer>
    );
};

export default React.memo(Progress);
