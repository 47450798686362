import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import classNames from "classnames";
import Tooltip from "../../Tooltip";
import gsap from "gsap";
import { useRecoilValue } from "recoil";
import { selectors } from "../../../recoil/atom";

const transitionOutDurPc = 0.2;
const transitionOutDurMobile = 0.2;

const out2 = "cubic-bezier(0.33, 1, 0.68, 1)";

const ChipContainer = styled.div`
 

    .chip {
        position: relative;

        display: flex;
        justify-content: center;
        align-items: center;

        background: #fff;

        transition-property: color, background-color, box-shadow;
        transition-timing-function: ${out2};
        transition-duration: ${transitionOutDurPc}s;
        cursor: pointer;

        font-weight: 700;
        font-size: clamp(12px, 0.9375vw, 18px);
        line-height: 1.25;
        letter-spacing: -0.03em;

        user-select: none;

        text-align: center;

        word-break: keep-all;
        word-wrap: break-word;

        .current & {
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
        }

        @media screen and (max-width: 1500px) {
            font-size: 12px;
        }

        @media screen and (max-width: 1055px) {
            transition-duration: ${transitionOutDurMobile}s;
        }

        .text-wrapper {
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;

            overflow: hidden;

            span {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        &:hover {
            background: #fafafa;

            .tooltip {
                pointer-events: auto;

                opacity: 1;
                transition-property: opacity;
                transition-duration: 0.2s;
                transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
            }
        }

        width: 100%;
        height: 6.25vw;
        border-radius: 3.1vw;
        padding: 0.8em 1.6em;
        @media screen and (max-width: 1055px) {
            width: 100%;
            height: 100%;

            font-size: 12px;
            line-height: 18px;
            border-radius: 170px;
            padding: 13px 25px;
        }
        &.big {
            width: 14vw;
            height: 26vw;

            border-radius: 8.85vw;

            font-size: 22px;

            @media screen and (max-width: 1500px) {
                font-size: clamp(12px, 0.9375vw, 18px);
            }

            @media screen and (max-width: 1055px) {
                // font-size: 16px;
                // line-height: 24px;

                width: 150px;
                height: 270px;
                border-radius: 170px;
            }
        }

        &.small {
            font-size: 14px;

            width: 5.1em;
            height: 3.14em;

            @media screen and (max-width: 1055px) {
                font-size: 10px;
                padding: 0;
            }
        }

        input {
            width: 0;
            height: 0;
            opacity: 0;
        }

        &:active {
            box-shadow: none;
        }

        &.animating {
            pointer-events: none;
        }
    }
`;

const Chip = ({
    value,
    text,
    name,
    checked,
    handleChange,
    id,
    big,
    small,
    tooltipText,
    ...rest
}) => {
    const chip = useRef(null);
    const [hasTransition, setHasTransition] = useState(true);
    const isAnimating = useRecoilValue(selectors["getIsSlideAnimating"]);

    useEffect(() => {
        setHasTransition(false);
        const tl = gsap.timeline({
            onComplete() {
                setHasTransition(true);
            },
        });

        const bg = chip.current;
        const text = chip.current.querySelector(".text-wrapper span");

        if (checked) {
            gsap.killTweensOf([bg, text]);
            tl.addLabel("start")
                .to(
                    bg,
                    {
                        backgroundColor: "#000",
                        duration: 0.2,
                        ease: "power1.inOut",
                    },
                    "start"
                )
                .to(
                    text,
                    {
                        color: "#fff",
                        duration: 0.2,
                        ease: "power1.inOut",
                    },
                    "start"
                );
        } else {
            gsap.killTweensOf([bg, text]);
            tl.addLabel("start")
                .to(
                    bg,
                    {
                        backgroundColor: "#fff",
                        duration: 0.2,
                        ease: "power2.out",
                    },
                    "start"
                )
                .to(
                    text,
                    {
                        color: "#000",
                        duration: 0.2,
                        ease: "power2.out",
                    },
                    "start"
                )
                .set([bg, text], {
                    clearProps: "all",
                });
        }
    }, [checked]);

    return (
        <ChipContainer className="chip-wrapper">
            <label
                ref={chip}
                htmlFor={id}
                className={classNames(
                    "chip",
                    checked && "checked",
                    big && "big",
                    small && "small",
                    !hasTransition && "no-transition",
                    isAnimating && "animating"
                )}
            >
                {tooltipText && <Tooltip text={tooltipText} />}
                <input
                    id={id}
                    name={name}
                    value={value}
                    onClick={handleChange}
                    onChange={handleChange}
                    checked={checked}
                    type="radio"
                    {...rest}
                />
                <div className="text-wrapper">
                    <span>{text}</span>
                </div>
            </label>
        </ChipContainer>
    );
};

export default React.memo(Chip);
