import styled from "@emotion/styled";
import React, { useCallback, useEffect, useState } from "react";
import CheckButton, { hidePoint } from "../components/Button/CheckButton";
import gsap from "gsap";
import { theme } from "../theme";
import { sendViewData } from "../data/titleData";
import { useRecoilValue } from "recoil";
import { selectors } from "../recoil/atom";
import usePost from "../hooks/usePost";
import useAnimation from "../hooks/useAnimation";
import useAlert from "../hooks/useAlert";

const StyledContainer = styled.div`
    .style-wrap {
        padding-right: 0;
        justify-content: center;
        @media screen and (max-width: 1055px) {
            justify-content: center;
        }
        .title {
            font-size: 2.6vw;
            letter-spacing: -0.01em;

            transition: color 0.5s;

            position: relative;
            z-index: 1;

            min-height: unset;
            height: auto !important;

            line-height: 1.15;

            @media screen and (max-width: 1055px) {
                font-size: 28px;
                line-height: 1.2;
                min-height: unset !important;
                height: auto !important;
                margin-bottom: 50px;
            }

            .small-text {
                font-size: 0.7vw;
                line-height: 1.9;
                letter-spacing: -0.03em;

                margin-top: 1.2vw;

                @media screen and (max-width: 1980px) {
                    font-size: 16px;
                }

                @media screen and (max-width: 1055px) {
                    margin-top: 15px;
                }
            }
        }

        .input-container {
            width: 100%;
        }
    }
`;

const SendFormView = () => {
    const { sendPost } = usePost();
    const { next } = useAnimation();
    const { toastAlert } = useAlert();

    const apiLoading = useRecoilValue(selectors["getApiLoading"]);
    const apiError = useRecoilValue(selectors["getApiError"]);
    const apiSuccess = useRecoilValue(selectors["getApiSuccess"]);
    const isMobile = useRecoilValue(selectors["getIsMobileWidth"]);
    const lang = useRecoilValue(selectors["getLanguage"]);
    const { title, smallText, basicErrorText, phoneErrorText } =
        sendViewData[lang];

    const [points, setPoints] = useState([]);

    const moveNext = useCallback(() => {
        document.body.classList.remove("dark-mode");
        document.body.classList.add("final-view");
        const tl = gsap.timeline();

        tl.to(".back-container", {
            opacity: 0,
            ease: "power1.inOut",
            duration: theme.buttonOpDur,
        })
            .set(
                ".step-container",
                {
                    justifyContent: "flex-end",
                },
                ">+.2"
            )
            .set(
                ".back-container",
                {
                    display: "none",
                },
                ">+.1"
            )
            .set(
                ".loading-container",
                {
                    zIndex: -1,
                },
                ">"
            );
        const tl2 = gsap.timeline();

        tl2.delay(
            theme.bigButtonDelay +
                (isMobile
                    ? theme.stepButtonDelay.mobile
                    : theme.stepButtonDelay.pc) +
                1.8
        )
            .set(".home-container", {
                display: "flex",
            })
            .set(".step-container", {
                justifyContent: "flex-end",
            })
            .to(".home-container", {
                opacity: 1,
                ease: "power1.inOut",
                duration: theme.buttonOpDur,
            });

        const loadingFinish = document.querySelector(".loading-finished");

        if (loadingFinish) {
            loadingFinish.classList.remove("loading-finished");
        }

        next();
    }, [next, isMobile]);

    const handleClick = (e) => {
        if (e.target.classList.contains("disabled")) {
            return;
        }
        if (apiLoading) {
            return;
        }

        sendPost();
    };

    useEffect(() => {
        if (apiError && points.length !== 0) {
            toastAlert(basicErrorText, () => {
                hidePoint(isMobile, points);
            });
            setPoints([]);
            return;
        }
    }, [
        apiError,
        toastAlert,
        basicErrorText,
        phoneErrorText,
        points,
        isMobile,
    ]);

    const [stopped, setStopped] = useState(false);

    useEffect(() => {
        if (apiSuccess && stopped) {
            setStopped(false);
            moveNext();
            window.onbeforeunload = null;
        }
    }, [apiSuccess, moveNext, stopped]);

    return (
        <StyledContainer className="style-container" data-send data-step="7">
            <div className="style-wrap">
                <div className="title">
                    <div>{title}</div>
                    <div className="small-text">{smallText}</div>
                </div>
                <div className="input-container">
                    <CheckButton
                        text="send"
                        onClick={handleClick}
                        loading={apiLoading}
                        error={apiError}
                        setPoints={setPoints}
                        loadingFinish={apiSuccess}
                        setStopped={setStopped}
                    />
                </div>
            </div>
        </StyledContainer>
    );
};

export default React.memo(SendFormView);
