import React, { useRef } from "react";
import styled from "@emotion/styled";
import { useRecoilState, useRecoilValue } from "recoil";
import { atoms, selectors } from "../../recoil/atom";
import { additionalInfoData } from "../../data/titleData";
import classNames from "classnames";

const StyledFileUpload = styled.div`
    margin: 4px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    max-height: 43px;

    .file-name-preview {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        align-items: flex-start;

        padding-right: 25px;
        max-width: 800px;

        flex: 1;

        &.no-file {
            align-self: center;
        }

        @media screen and (max-width: 1055px) {
            width: calc(100% - 115px);
        }

        .file-item {
            display: flex;
            align-items: center;

            font-size: 18px;

            padding: 5px 0;

            @media screen and (max-width: 1500px) {
                font-size: 12px;
            }

            @media screen and (max-width: 1055px) {
                min-width: 115px;
                padding: 2.5px 20px;
            }

            .file-name {
                line-height: normal;
                letter-spacing: -0.03em;

                visibility: visible !important;

                margin: 3px;

                display: -webkit-box !important;
                -webkit-line-clamp: 1 !important;
                -webkit-box-orient: vertical !important;
                overflow: hidden !important;

                word-break: keep-all;
                word-wrap: break-word;

                word-break: break-all;
            }

            .file-size {
                padding-left: 0.3em;
                margin-right: 3px;
            }

            position: relative;

            padding-left: calc(1.3em + 9px);
            padding-right: calc(1.4em + 5px);
            max-width: 100%;
            &.no-item {
                padding-right: 0;
            }
            &.mobile-file {
                display: none;
            }

            &::before {
                content: "";

                position: absolute;
                left: 0;
                top: 50%;

                transform: translate(0, -50%);

                width: 1.3em;
                height: 100%;

                background: url("/svg/file-upload.svg");

                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }

            .delete {
                cursor: pointer;
                content: "";
                position: absolute;
                top: 50%;
                right: 0;

                transform: translate(0, -50%);

                background-color: #000;

                width: 1.4em;
                height: 1.4em;

                border-radius: 50%;

                background-image: url("/svg/close.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: 50% 50%;
            }
        }
    }

    input {
        width: 0;
        height: 0;
        opacity: 0;
    }

    label {
        cursor: pointer;
    }

    .file-label {
        display: inline-block;

        font-size: 14px;
        letter-spacing: -0.03em;
        line-height: 1.5;
        font-weight: 700;

        user-select: none;

        padding: 0.8em 1.3em;

        background: #fff;
        border-radius: 12em;

        transition-property: color, background-color, box-shadow;
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);

        cursor: pointer;

        min-width: 135px;
        width: 0;
        text-align: center;

        white-space: nowrap;

        .current & {
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
        }

        @media screen and (max-width: 1055px) {
            [lang="kr"] & {
                min-width: 115px;
            }

            [lang="en"] & {
                min-width: 135px;
            }
            font-size: 12px;
            padding: 9px 25px;
            max-width: 40%;
        }

        &:hover {
            background: #fafafa;
        }

        &:active {
            box-shadow: none;
        }
    }
`;

const getFileSize = (size) => {
    const mb = (size / 1024 / 1024).toFixed(1) * 1;
    if (mb === 0) {
        const kb = (size / 1024).toFixed(1) * 1;

        return `${kb}KB`;
    }
    return `${mb}MB`;
};

const File = ({ coilKey, name, id, label, toastAlert }) => {
    const lang = useRecoilValue(selectors["getLanguage"]);

    const { uploadPlaceholder, uploadedPlaceholder, msg1, msg2, msg3 } =
        additionalInfoData[lang];

    const [file, setFile] = useRecoilState(atoms[coilKey]);
    const ref = useRef(null);

    const MAX_SIZE = 20 * 1024 * 1024;

    const _handleUpload = (e) => {
        const files = [...e.target.files];
        let res = file.file;

        if ([...res, ...files].length > 3) {
            toastAlert(msg1);

            res = [...res, ...files].slice(0, 3);
            setFile({
                ...file,
                [name]: res,
            });
            return;
        }

        if (files.filter((f) => f.size > MAX_SIZE).length !== 0) {
            toastAlert(msg2);
            return;
        }

        const extenderReg =
            /\.pdf$|\.ppt$|\.word$|\.exel$|\.zip$|\.jpg$|\.jpeg$|\.png$/;
        if (files.filter((f) => !extenderReg.test(f.name)).length !== 0) {
            toastAlert(msg3);

            return;
        }

        res = [...res, ...files];

        setFile({
            ...file,
            [name]: res,
        });
    };

    const _handleDelete = (e) => {
        const deleteIndex = e.target.dataset.index;
        const newFiles = file.file.filter((f, i) => deleteIndex * 1 !== i);

        setFile({
            ...file,
            file: newFiles,
        });
    };

    return (
        <StyledFileUpload className="file-container">
            <div
                className={classNames(
                    "file-name-preview",
                    file.file &&
                        (file.file.length === 0 || file.file.length === 1) &&
                        "no-file"
                )}
            >
                {file.file && file.file.length !== 0 ? (
                    file.file.map((f, i) => (
                        <div key={f.name} className="file-item">
                            <div className="file-name">{f.name}</div>
                            <span className="file-size">
                                {getFileSize(f.size)}
                            </span>
                            <span
                                className="delete"
                                data-index={i}
                                onClick={_handleDelete}
                            ></span>
                        </div>
                    ))
                ) : (
                    <label htmlFor={id} className="file-item no-item">
                        <div className="file-name">{uploadPlaceholder}</div>
                    </label>
                )}
                <div className="mobile-file file-item">
                    {file.file && uploadedPlaceholder(file.file.length)}
                </div>
            </div>
            <label htmlFor={id} className="file-label" ref={ref}>
                <input
                    id={id}
                    type="file"
                    multiple
                    onChange={_handleUpload}
                    onClick={(e) => {
                        e.target.value = null;
                    }}
                />
                <span>{label}</span>
            </label>
        </StyledFileUpload>
    );
};

export default File;
