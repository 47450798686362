import React, { useCallback, useRef } from "react";
import { useRecoilState } from "recoil";
import styled from "@emotion/styled";
import { atoms } from "../../recoil/atom";
import classNames from "classnames";
import Warn from "../Warn";
import { PhoneNumberUtil } from "google-libphonenumber";

const StyledInputContainer = styled.div`
    margin: 4px 0 0;
    position: relative;
    &:first-of-type {
        margin-top: 0;
    }

    input {
        font-weight: bold;
        letter-spacing: -0.03em;
        line-height: 1.5;

        padding: 0.28em 1em;
        border-radius: 2.4em;

        height: 2em;
        width: calc(100% + 2.4em / 2);

        outline: none;
        border: none;

        transition-property: color, background-color, box-shadow;
        transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);

        background: #fff;
        color: #000;

        font-size: clamp(20px, 1.56vw, 30px);
        padding-right: calc(1.2em + 25px);

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .current & {
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
        }

        @media screen and (max-width: 1055px) {
            font-size: 20px;
            line-height: 30px;
        }
        &.big {
            font-size: 4vw;
            @media screen and (max-width: 1055px) {
                font-size: 36px;
                line-height: 54px;

                padding: 43px calc(1.2em + 25px) 43px 70px;

                height: 140px;
            }
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -moz-box-shadow: 0 0 0 100px white inset !important;
            -webkit-box-shadow: 0 0 0 100px white inset !important;
            box-shadow: 0 0 0 150px white inset !important;
            background: white !important;
            transition: background-color 5000s;
            -webkit-text-fill-color: currentColor !important;

            @media screen and (max-width: 1055px) {
                transition: unset;
                -webkit-appearance: none;
            }
        }

        &::placeholder {
            font-weight: 700;
            font-weight: bold;
            letter-spacing: -0.03em;
            line-height: 1.5;

            color: #ccc;
        }

        &:hover {
            background: #fafafa;
        }

        &:active {
            box-shadow: none;
        }

        &:focus {
            background: #000;
            color: #fff;
            &::selection {
                background: #fff;
                color: #000;
            }
        }
    }
`;

const Input = ({
    placeholder,
    coilKey,
    name,
    big,
    errorText,
    onBlur,
    ...rest
}) => {
    const [value, setValue] = useRecoilState(atoms[coilKey]);
    const inputRef = useRef(null);

    const _handleChange = useCallback(
        (e) => {
            if (e.target.dataset.type === "number") {
                let newValue = e.target.value;

                const textFilter = /[^(0-9)|-]/g;
                newValue = newValue.replace(textFilter, "");

                const length = newValue.length;
                const trimmedLength = newValue.replace(/-/g, "").length;

                if (trimmedLength > 11) {
                    return;
                }
                if (length > 2) {
                    const phoneUtil = new PhoneNumberUtil();

                    const phone = phoneUtil.parseAndKeepRawInput(
                        newValue,
                        "kr"
                    );
                    const formatted = phoneUtil.formatInOriginalFormat(
                        phone,
                        "kr"
                    );

                    setValue((value) => ({ ...value, [name]: formatted }));
                } else {
                    setValue((value) => ({ ...value, [name]: newValue }));
                }
            } else {
                setValue((value) => ({ ...value, [name]: e.target.value }));
            }
        },
        [name, setValue]
    );

    const _handleBlur = useCallback(() => {
        if (onBlur) {
            const input = inputRef.current.querySelector("input");

            onBlur(input);
        }
    }, [onBlur]);

    return (
        <StyledInputContainer ref={inputRef}>
            <input
                placeholder={placeholder}
                name={name}
                value={value[name]}
                onChange={_handleChange}
                className={classNames(big && "big")}
                onBlur={_handleBlur}
                spellCheck={false}
                {...rest}
            />
            <Warn />
            {errorText && <Warn errorText={errorText} />}
        </StyledInputContainer>
    );
};

export default React.memo(Input);
