import styled from "@emotion/styled";
import React from "react";
import classNames from "classnames";
import { useRecoilState, useRecoilValue } from "recoil";
import { atoms, selectors } from "../../../recoil/atom";
import NormalInput from "../../Input/NormalInput";
import Chip from "./Chip";


const ChipsContainer = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-itmes: stretch;
    gap: 4px;

    @media screen and (max-width: 1055px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

const MultiSelectChip = ({ selects, vertical, coilKey, startTabIndex }) => {
    const lang = useRecoilValue(selectors["getLanguage"]);
    const [checks, setChecks] = useRecoilState(atoms[coilKey]);

    const _handleChange = (value, isChecked) => {
        if (isChecked) {
            setChecks((checks) => [...checks, value]);
        } else if (!isChecked && checks.indexOf(value) !== -1) {
            setChecks((checks) => [...checks.filter((c) => c !== value)]);
        }
    };

    const projectRange = useRecoilValue(selectors["getRequestRangeProject"]);

    console.log(projectRange)

    return (
        <>
        <ChipsContainer className="chip-container">
            {selects.map((select, i) => (
                <Chip
                    id={select.id}
                    key={select.id}
                    name={coilKey}
                    value={select}
                    isChecked={checks.indexOf(select) !== -1}
                    text={select.name}
                    handleChange={_handleChange}
                    vertical={vertical}
                    data-tabindex={i + 1 + startTabIndex || i + 1}
         
                />
            ))}
        </ChipsContainer>
        <div
            className={classNames(
                "etc-detail",
                projectRange.filter(v => v.id == 18).length > 0 && "show"
            )}
        >
            <NormalInput
                placeholder="필요한 업무 내용을 알려주세요 (선택 사항)"
                type="text"
                coilKey="requestRangeProjectEtc"
            />
        </div>
        </>
    );
};

export default React.memo(MultiSelectChip);
