import { useRecoilState, useRecoilValue } from "recoil";
import { atoms, formatMonth, selectors } from "../recoil/atom";

import { useCallback } from "react";
import client from "../api/client";

const usePost = () => {
    const [, setApiLoading] = useRecoilState(atoms["apiLoading"]);
    const [, setApiError] = useRecoilState(atoms["apiError"]);
    const [, setApiSuccess] = useRecoilState(atoms["apiSuccess"]);

    // step1
    const reqType = useRecoilValue(selectors["getRequestType"]);

    // step2
    const requester = useRecoilValue(selectors["getRequesterInfo"]);

    // step3
    const requestProject = useRecoilValue(selectors["getRequestProject"]);

    // Project step4
    const brandRange = useRecoilValue(selectors["getRequestRangeBrand"]);
    const UIRange = useRecoilValue(selectors["getRequestRangeUI"]);

    // Project step5
    const projectRange = useRecoilValue(selectors["getRequestRangeProject"]);

    // Project step6
    const startDue = useRecoilValue(selectors["getStartDue"]);
    const endDue = useRecoilValue(selectors["getEndDue"]);
    const scheduleAdjust = useRecoilValue(selectors["getScheduleAdjust"]);

    // Project step7
    const price = useRecoilValue(selectors["getRequestPrice"]);

    // Request step4
    const kindRange = useRecoilValue(selectors["getRequestRangeKind"]);
    const kindDetail = useRecoilValue(selectors["getKindEtcDetail"]);

    // Additional Info
    const additionalInfo = useRecoilValue(selectors["getAdditionalInfo"]);
    const requestRangeProjectEtc = useRecoilValue(selectors["getRequestRangeProjectEtc"])
    const createData = useCallback(
        async (fileIds) => {
            let url,
                contentType = "application/json";

            const result = {};

            result.assignee = {
                name: requester.name,
                company: {
                    name: requester.company,
                },
                phone: requester.contact,
                department: requester.department,
                position: requester.duty,
                email: requester.email,
            };

            result.description = additionalInfo.text;
            result.attachments = fileIds;

            if (reqType === "etc") {
                url = "/papers/etc";
                result.group = "etc";
                result.title = requestProject.requestName;
                result.request_type = kindRange;
                result.request_type_description = kindDetail;
            } else {
                url = "/papers/project";
                result.group = "project";
                result.through_contact = true;
                result.title = requestProject.projectName;
                result.category = projectRange.map(p => p.id);
                result.start_date = `${startDue.year}-${formatMonth(
                    startDue.month
                )}-${formatMonth(10 * startDue.due + 1)}`;
                result.end_date = `${endDue.year}-${formatMonth(
                    endDue.month
                )}-${formatMonth(10 * endDue.due + 1)}`;
                result.is_schedule_adjustable =
                    scheduleAdjust === "yes" ? true : false;
                result.budget = price * 1;
                result.request_type_description = requestRangeProjectEtc;
            }

            console.log({result})

            return [result, url, contentType];
        },
        [
            requester,
            requestProject,
            UIRange,
            brandRange,
            kindRange,
            kindDetail,
            scheduleAdjust,
            projectRange,
            price,
            endDue,
            startDue,
            additionalInfo,
            reqType,
            requestRangeProjectEtc
        ]
    );

    const uploadFile = useCallback(async () => {
        const fileIds = [];
        for (let i = 0; i < additionalInfo.file.length; i++) {
            const fd = new FormData();
            fd.append("file", additionalInfo.file[i]);
            const res = await client.post("/attachments", fd, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            fileIds.push(res.data.id);
        }

        return fileIds;
    }, [additionalInfo]);

    const sendPost = useCallback(async () => {
        try {
            setApiLoading(true);
            setApiError(false);
            const ids = await uploadFile();
            const [data, url] = await createData(ids);

            await client.post(url, data);

            setApiSuccess(true);

        } catch (e) {
            setApiLoading(false);
            setApiSuccess(false);
            setApiError(e);
            console.log(e)
        }
        setApiLoading(false);
    }, [createData, uploadFile, setApiLoading, setApiError, setApiSuccess]);

    const resetSuccess = useCallback(() => {
        setApiLoading(false);
    }, [setApiLoading]);

    return { sendPost, resetSuccess };
};

export default usePost;
