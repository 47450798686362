import ProjectNameView from "./ProjectNameView";
import ProjectPriceView from "./ProjectPriceView";
import ProjectRangeView from "./ProjectRangeView";
import ProjectRequestView from "./ProjectRequestView";
import RequestKindView from "./RequestKindView";
import RequestNameView from "./RquestNameView";
import ProjectDueView from "./ProjectDueView";
import { adjustScheduleText } from "../data/titleData";
import { useRecoilValue, selectors } from "recoil";
const ProjectViews = () => {

    return (
        <>
            {/* <ProjectNameView /> */}
            {/* <ProjectRangeView /> */}
            <ProjectRequestView />
            <ProjectPriceView />
            <ProjectDueView />
        </>
    );
};

const RequestViews = () => {
    return (
        <>
            {/* <RequestNameView /> */}
            <RequestKindView />
        </>
    );
};

const Views = [ProjectViews, RequestViews];

export default Views;
