import styled from "@emotion/styled";
import gsap from "gsap";
import React, { useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import CheckButton from "../components/Button/CheckButton";
import { checkPrivacyData } from "../data/titleData";
import useAnimation from "../hooks/useAnimation";
import { atoms, selectors } from "../recoil/atom";
import { theme } from "../theme";

const labelPadding = 30;
const responsiveLabelPadding = 12;

const StyledContainer = styled.div`
    .style-wrap {
        border: 3px solid blue;
        padding-right: 0;
        justify-content: center;
        @media screen and (max-width: 1055px) {
            justify-content: center;
        }

        .title {
            transition: color 0.5s;

            min-height: unset !important;
            height: auto !important;

            @media screen and (max-width: 1055px) {
                min-height: unset !important;
                height: auto !important;
                margin-bottom: 50px;
            }
        }
    }

    .privacy-container {
        flex: 1;
        z-index: 3;

        input {
            width: 0;
            height: 0;
            opacity: 0;
        }

        label {
            cursor: pointer;
            padding: 0 30px;

            display: flex;
            width: fit-content;

            position: relative;
            padding-left: ${labelPadding}px;
            left: -${labelPadding}px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @media screen and (max-width: 1055px) {
                padding: 0;
                padding-left: ${responsiveLabelPadding}px;
                padding-right: ${responsiveLabelPadding + 20}px;
                left: -${responsiveLabelPadding}px;
            }
        }

        span {
            position: relative;
            font-size: 1vw;
            letter-spacing: -0.03em;
            line-height: 1;
            font-weight: 400;

            padding-left: 50px;

            @media screen and (max-width: 1980px) {
                font-size: 12px;
                padding-left: calc(1.56vw + 20px);
            }

            button {
                padding: 30px 0;
                font-size: inherit;
                letter-spacing: inherit;
                line-height: inherit;
                font-weight: inherit;

                background: transparent;
                outline: none;
                border: none;

                text-decoration-line: underline;
                text-underline-position: under;

                cursor: pointer;

                transition: color 0.5s;

                color: #000;

                .dark-mode & {
                    color: #fff;
                }

                @media screen and (max-width: 1055px) {
                    padding: 20px 0;
                }
            }

            &::before {
                content: "";

                position: absolute;
                left: 0;
                top: 50%;

                transform: translate(0, -50%);

                background: #fff;

                width: 30px;
                height: 30px;
                @media screen and (max-width: 1055px) {
                    width: 18px;
                    height: 18px;
                }
                transition: background 0.5s;
                .current & {
                    box-shadow: inset 2px 2px 1px rgba(0, 0, 0, 0.15);
                }

                .dark-mode & {
                    background: #1e1e1e;
                }
            }

            & .after {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;

                margin: auto;

                opacity: 0;
                z-index: 6;

                background: #000;

                -webkit-backface-visibility: hidden;

                -webkit-mask: url(/svg/check-line.svg) no-repeat 50% 50%;
                mask: url(/svg/check-line.svg) no-repeat 50% 50%;

                mask-size: 30px 30px;

                width: 30px;
                height: 30px;
                transition: background 0.3s cubic-bezier(0.33, 1, 0.68, 1),
                    opacity 0.3s cubic-bezier(0.33, 1, 0.68, 1);

                .dark-mode & {
                    background: #fff;
                }
                @media screen and (max-width: 1055px) {
                    width: 18px;
                    height: 18px;
                    mask-size: 18px;
                }
            }
        }

        input:checked + span .after {
            opacity: 1;
        }
    }
    .input-container {
        width: 100%;
    }
`;

const CheckPrivacyView = () => {
    const isSlideAnimating = useRecoilValue(selectors["getIsSlideAnimating"]);
    const isMobile = useRecoilValue(selectors["getIsMobileWidth"]);

    const lang = useRecoilValue(selectors["getLanguage"]);
    const { title } = checkPrivacyData[lang];

    const [checked, setChecked] = useRecoilState(atoms["privacyCheck"]);

    const { next } = useAnimation();

    const handleChange = (e) => {
        setChecked(!checked);
    };

    const handleClick = useCallback(
        (e, btn) => {
            if (
                !isSlideAnimating &&
                !btn.current.classList.contains("disabled")
            ) {
                btn.current.classList.add("clicked");
                btn.current.classList.add("click-occured");
                next();
                document.querySelector(".info-container").scrollTop = 0;
                const tl = gsap.timeline();

                tl.delay(
                    theme.bigButtonDelay +
                        (isMobile
                            ? theme.stepButtonDelay.mobile
                            : theme.stepButtonDelay.pc)
                )
                    .set(".next-container", {
                        display: "flex",
                    })
                    .to(".next-container", {
                        opacity: 1,
                        ease: "power1.inOut",
                        duration: theme.buttonOpDur,
                    });
            }
        },
        [isMobile, isSlideAnimating, next]
    );

    useEffect(() => {
        const privacyBtn = document.querySelector(".privacy-container");

        const clickHandler = (e) => {
            if (e.target.classList.contains("show-privacy")) {
                const tl = gsap.timeline();

                if (document.querySelector('.modal-container')) {
                    document.querySelector('.modal-container').classList.add('is-active');
                }

                tl.addLabel("setting")
                    .set(
                        ".modal-container",
                        {
                            display: "flex",
                        },
                        "setting"
                    )
                    .set(
                        ".modal-wrap",
                        {
                            opacity: 0,
                        },
                        "setting"
                    )
                    .addLabel("start", ">")
                    .to(
                        ".modal-container",
                        {
                            opacity: 1,
                            duration: isMobile ? 0.3 : 0.4,
                            ease: "power1.inOut",
                        },
                        "start"
                    )
                    .to(
                        ".modal-wrap",
                        {
                            opacity: 1,
                            duration: isMobile ? 0.2 : 0.4,
                            ease: "power1.inOut",
                        },
                        isMobile ? ">-.2" : ">-.3"
                    );
            }
        };

        privacyBtn.addEventListener("click", clickHandler);

        return () => {
            privacyBtn.removeEventListener("click", clickHandler);
        };
    }, [isMobile]);

    useEffect(() => {
        setChecked(false);
    }, [setChecked]);

    return (
        <StyledContainer>
            <div className="privacy-container">
                <label htmlFor="privacy">
                    <input
                        type="checkbox"
                        id="privacy"
                        checked={checked}
                        onChange={handleChange}
                    />
                    <span>
                        {title}
                        <div className="after"></div>
                    </span>
                </label>
            </div>
        </StyledContainer>
    );
};

export default React.memo(CheckPrivacyView);
