import styled from "@emotion/styled";
import React from "react";
import { useRecoilValue } from "recoil";
import { finalViewData } from "../data/titleData";
import { selectors } from "../recoil/atom";

const StyledContainer = styled.div`
    .style-wrap {
        justify-content: center;
        @media screen and (max-width: 1055px) {
            justify-content: center;
        }
        .title {
            font-size: 4vw;
            font-weight: 700;
            line-height: 1;
            letter-spacing: -0.01em;

            min-height: unset !important;
            height: auto !important;

            @media screen and (max-width: 1500px) {
                font-size: 60px;
            }

            @media screen and (max-width: 1055px) {
                min-height: unset !important;
                height: auto !important;
                margin-bottom: 50px;
            }

            .title-word {
                position: relative;
                display: inline-block;

                .word-line {
                    position: relative;
                    display: inline-block;

                    &.space {
                        padding: 0 0.1em;
                    }
                }
                .title-char {
                    position: relative;
                    display: inline-block;
                }
            }

            .point {
                position: relative;

                color: #ff0000;
                margin-left: 0.46vw;
                display: inline-block;
            }
        }

        .input-container {
            font-size: 1.1vw;
            line-height: 1.6;
            letter-spacing: -0.04em;

            font-weight: 400;

            @media screen and (max-width: 1500px) {
                font-size: 16px;
            }

            @media screen and (max-width: 1055px) {
                width: 100%;

                line-height: 2;
            }

            .bold {
                font-weight: 700;
            }

            .confirm {
                word-break: keep-all;
            }
        }
    }
`;

const FinalView = () => {
    const lang = useRecoilValue(selectors["getLanguage"]);
    const { confirm } = finalViewData[lang];

    const requester = useRecoilValue(selectors["getRequesterInfo"]);
    const requestType = useRecoilValue(selectors["getRequestType"]);
    const projectName = useRecoilValue(selectors["getRequestProject"]);

    return (
        <StyledContainer className="style-container thank-you" data-final data-line data-step="8">
            <div className="style-wrap">
                <div className="title">
                    <div className="title-wrapper">
                        <div className="title-word">
                            <div className="word-line word-thank">
                                <div className="title-char">T</div>
                                <div className="title-char">h</div>
                                <div className="title-char">a</div>
                                <div className="title-char">n</div>
                                <div className="title-char">k</div>
                            </div>
                            <div className="word-line space"></div>
                            <div className="word-line word-you">
                                <div className="title-char">Y</div>
                                <div className="title-char">o</div>
                                <div className="title-char">u</div>
                                <span className="point">.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="input-container">
                    {confirm(requester, requestType, projectName)}
                </div>
            </div>
        </StyledContainer>
    );
};

export default React.memo(FinalView);
