import React from "react";
import styled from "@emotion/styled";
import ChoiseChip from "../components/Chip/ChoiseChip";
import { typeData } from "../data/formData";
import { requestTypeTitle } from "../data/titleData";
import { useRecoilValue } from "recoil";
import { selectors } from "../recoil/atom";

const StyledMainContainer = styled.div`
    .input-container {
        display: flex;
        justify-content: flex-end;
    }
    .chip-container {
        grid-template-columns: repeat(2, 1fr) !important;
        gap: 4px;
    }
`;
const RequestTypeView = () => {
    const lang = useRecoilValue(selectors["getLanguage"]);
    const { title } = requestTypeTitle[lang];

    return (
        <>
            <StyledMainContainer
                className="style-container"
                data-line={true}
                data-step="1"
            >
                <div className="style-wrap">
                    <div className="title">
                        {/* <div className="step">Step. 01</div> */}
                        <div>{title}</div>
                    </div>
                    <div className="input-container">
                        <ChoiseChip
                            choises={typeData}
                            big
                            coilKey="requestType"
                        />
                    </div>
                </div>
            </StyledMainContainer>
        </>
    );
};

export default React.memo(RequestTypeView);
