import gsap from "gsap";
import { useCallback, useEffect, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { closeAlert } from "../components/Alert";
import { atoms, selectors } from "../recoil/atom";
import { theme } from "../theme";

gsap.ticker.fps(144);

const useAnimation = () => {
    const [, setIsSlideAnimating] = useRecoilState(atoms["isSlideAnimating"]);
    const globalTl = useRef(gsap.timeline());

    const isMobile = useRecoilValue(selectors["getIsMobileWidth"]);
    const scheduleAdjust = useRecoilValue(selectors["getScheduleAdjust"]);

    const disappearDur = isMobile ? 0.7 : 1;
    const disappearEase = isMobile ? "power2.in" : "power2.in";

    const [, setCurrentActive] = useRecoilState(atoms["currentActive"]);

    useEffect(() => {
        gsap.killTweensOf(window);
    }, []);

    const progressBarAnimation = useCallback((ratio, isMain, hideNShow) => {
        if (hideNShow) {
            const tl = gsap.timeline();

            tl.set(".progress", {
                scaleX: 0,
            })
                .addLabel("start", ">")
                .to(".progress", {
                    scaleX: ratio,
                    ease: "power1.inOut",
                    duration: 1.2,
                });

            return;
        }

        gsap.to(".progress", {
            scaleX: ratio,
            ease: "power1.out",
            duration: isMain ? 0.7 : 0.8,
        });
    }, []);

    const UIin = useCallback(() => {
        const tl = gsap.timeline();

        const header = document.querySelector(".header-container");
        const step = document.querySelector(".step-container");
        tl.addLabel("start").to([header, step], {
            autoAlpha: 1,
            stagger: 0.1,
            ease: "power1.inOut",
            duration: 0.5,
            onComplete() {
                gsap.set(header, {
                    pointerEvents: "auto",
                });
            },
        });
    }, []);

    /**
     * Privacy Animations
     */
    // Privacy deactivate animation
    const privacyDeactiveAnimation = useCallback(
        (slide, dir, title, input) => {
            const tl = globalTl.current;

            const checkBtn = slide.querySelector("button[data-text]");
            const checkClone = slide.querySelector(".border-clone");
            const before = checkBtn.querySelector(".before");
            const checkText = before.querySelector(".before-text");

            const points = before.querySelectorAll(".ing-char");

            // const left = (window.innerWidth / 100) * 11 - input.clientWidth;
            const left = -input.clientWidth;

            const xTo = isMobile ? "0" : "0vw";

            tl.addLabel("prevSlideStart").addLabel("privacyStart");
            if (slide.dataset.send) {
                tl.addLabel("point", "privacyStart")
                    .to(
                        points,
                        {
                            autoAlpha: 0,
                            duration: 0.5,
                            ease: "power1.inOut",
                            stagger: -0.07,
                        },
                        "point"
                    )
                    .to(
                        points,
                        {
                            y: "100%",
                            duration: 0.3,
                            ease: "power2.in",
                            stagger: -0.07,
                        },
                        "point"
                    )
                    .addLabel("start", ">-=.2");
            }

            if (slide.dataset.check) {
                tl.addLabel("start", "privacyStart");
            }

            tl.to(
                [checkBtn, checkClone],
                {
                    height: "0px",
                    duration: 0.65,
                    ease: "power1.in",
                },
                "start"
            )
                .addLabel("checkEnd", ">-.4")
                .to(
                    [checkBtn, checkClone],
                    {
                        borderWidth: "1px",
                        duration: 0.3,
                        ease: "power2.out",
                        onComplete() {
                            if (slide.dataset.send) {
                                document.body.classList.remove("dark-mode");
                            } else {
                                document.body.classList.add("dark-mode");
                            }
                        },
                    },
                    ">"
                )
                .addLabel("moveStart", "checkEnd")
                .to(
                    checkText,
                    {
                        x: `100vw`,
                        ease: "power2.in",
                        duration: disappearDur - 0.3,
                    },
                    "moveStart"
                )
                .to(
                    checkText,
                    {
                        autoAlpha: 0,
                        ease: "power1.inOut",
                        duration: disappearDur - 0.3,
                    },
                    "moveStart"
                )
                .to(
                    title,
                    {
                        x: `${-dir * 100}vw`,
                        ease: "power2.inOut",
                        duration: disappearDur,
                    },
                    "moveStart"
                )
                .to(
                    input,
                    {
                        x: `${left}px`,
                        ease: "power2.inOut",
                        duration: disappearDur + 0.3,
                        onComplete() {
                            input.style.transform = `translate(-100%, 0)`;
                            gsap.set(checkText, {
                                autoAlpha: 1,
                                x: 0,
                            });

                            const points = before.querySelectorAll(".ing-char");
                            if (points.length !== 0) {
                                gsap.set(points, {
                                    y: "100%",
                                });
                            }
                        },
                    },
                    "moveStart+=.1"
                )
                .to(
                    [checkBtn, checkClone],
                    {
                        scaleX: 0.1,
                        x: xTo,
                        ease: "power2.inOut",
                        duration: disappearDur + 0.3,
                    },
                    "moveStart+=.1"
                )
                .set(
                    [checkBtn, checkClone],
                    {
                        transformOrigin: "left",
                    },
                    "setting"
                )
                .to(
                    [checkBtn, checkClone],
                    {
                        scaleX: 0,
                        ease: "power2.out",
                        duration: 1,
                    },
                    "moveStart+=.8"
                )
                .addLabel("privacyEnd")
                .addLabel("end", "privacyEnd-=.8")
                .addLabel("nextRefer", "end");
        },
        [disappearDur, isMobile]
    );

    // Privacy activate animation
    const privacyActiveAnimation = useCallback(
        (slide, dir, title, input) => {
            const tl = globalTl.current;
            const checkBtn = slide.querySelector("button[data-text]");
            const checkClone = slide.querySelector(".border-clone");

            const checkTxt = checkBtn.querySelector(".text .text-line");
            const before = checkBtn.querySelector(".before");
            const beforeTxt = before.querySelector(".before-text");

            const heightTo = isMobile ? "140px" : "19.6vw";
            const borderTo = isMobile ? "6px" : "0.7vw";
            const smallBorderTo = isMobile ? "5px" : "0.5vw";

            const activeDur = 1.1;
            const activeEase = "power2.inOut";

            tl.addLabel("privacyStart", "prevSlideStart+=.3")
                .addLabel("start", "privacyStart")
                .set(
                    [title],
                    {
                        x: `${dir * 6}vw`,
                        autoAlpha: 0,
                    },
                    "setting"
                )
                .set(
                    [checkTxt, beforeTxt],
                    {
                        x: "-5vw",
                        autoAlpha: 0,
                    },
                    "setting"
                )
                .set(
                    [checkBtn, checkClone],
                    {
                        height: "0px",
                        transformOrigin: "0% 50%",
                        transform: "translate(0vw, 0px) scale(0, 1)",
                        borderWidth: "1px",
                    },
                    "setting"
                )
                .to(
                    [checkBtn, checkClone],
                    {
                        scaleX: 1,
                        duration: activeDur,
                        ease: activeEase,
                    },
                    "start"
                )
                .addLabel("lineScaleEnd", ">")
                .addLabel("moveStart", "lineScaleEnd-=.2")
                .to(
                    input,
                    {
                        x: "0",
                        ease: activeEase,
                        duration: activeDur,
                    },
                    "start"
                )
                .to(
                    [checkBtn, checkClone],
                    {
                        x: "0",
                        ease: activeEase,
                        duration: activeDur,
                    },
                    "start"
                )
                .addLabel("buttonAppear", "start+=.7")
                .to(
                    checkBtn,
                    {
                        borderWidth: smallBorderTo,
                        duration: 0.4,
                        ease: "power1.out",
                        onStart() {
                            document.body.classList.remove("dark-mode");
                            document.body.classList.remove("final-view");
                        },
                    },
                    "buttonAppear"
                )
                .to(
                    checkClone,
                    {
                        borderWidth: borderTo,
                        duration: 0.4,
                        ease: "power1.out",
                    },
                    "buttonAppear"
                )
                .to(
                    [checkBtn, checkClone],
                    {
                        height: heightTo,
                        duration: 0.4,
                        ease: "power1.in",
                    },
                    "buttonAppear"
                )
                .to(
                    [checkTxt, beforeTxt],
                    {
                        x: 0,
                        ease: "power2.out",
                        duration: 0.6,
                        onComplete() {
                            gsap.set([checkBtn, checkClone], {
                                clearProps: "all",
                            });
                        },
                    },
                    "lineScaleEnd"
                )
                .to(
                    [checkTxt, beforeTxt],
                    {
                        autoAlpha: 1,
                        ease: "power1.inOut",
                        duration: 0.6,
                    },
                    "lineScaleEnd"
                )
                .addLabel("checkTextEnd", ">")
                .to(
                    title,
                    {
                        x: 0,
                        ease: "power2.out",
                        duration: 0.65,
                    },
                    "checkTextEnd-=.32"
                )
                .to(
                    title,
                    {
                        autoAlpha: 1,
                        ease: "power1.inOut",
                        duration: 0.65,
                    },
                    "checkTextEnd-=.32"
                )
                .addLabel("buttonEnd")
                .addLabel("maskingStart", "buttonEnd");
        },
        [isMobile]
    );

    const finalSlideAnimation = useCallback(
        (slide, dir, title, input) => {

            console.log('finalSlideAnimation')
            const tl = globalTl.current;

            const point = title.querySelector(".point");

            const thank = title.querySelectorAll(".word-thank .title-char");
            const you = title.querySelectorAll(".word-you .title-char");

            const startPoint = isMobile ? "privacyEnd+=.26" : "privacyEnd+=.2";

            const slideDur = isMobile ? 0.5 : 0.7;
            const slideOpDur = isMobile ? 0.4 : 0.6;

            tl.addLabel("confirm", startPoint)
                .set(
                    input,
                    {
                        x: "14vw",
                        autoAlpha: 0,
                    },
                    "setting"
                )
                .to(
                    input,
                    {
                        x: 0,
                        duration: slideDur,
                        ease: "power2.out",
                    },
                    "confirm"
                )
                .to(
                    input,
                    {
                        autoAlpha: 1,
                        duration: slideOpDur,
                        ease: "power1.inOut",
                    },
                    "confirm"
                )
                .addLabel("thank", ">-.2");

            const textDur = isMobile ? 0.7 : 0.68;
            const textStagger = isMobile ? 0.12 : 0.088;
            const textEase = "power2.inOut";

            const xDist = Math.round((window.innerWidth / 100) * 1.2);
            const yDist = isMobile ? "120%" : "110%";

            tl.set(
                [point, you[1], you[2]],
                {
                    left: "-0.1em",
                },
                "setting"
            )
                .set(
                    title,
                    {
                        overflow: "hidden",
                        autoAlpha: 1,
                        x: 0,
                    },
                    "setting"
                )
                .set(
                    point,
                    {
                        y: yDist,
                    },
                    "setting"
                )
                .set(
                    thank,
                    {
                        y: yDist,
                        x: `${xDist}px`,
                        rotate: "14deg",
                    },
                    "setting"
                )
                .set(
                    you,
                    {
                        y: yDist,
                        x: `${xDist}px`,
                        rotate: "14deg",
                    },
                    "setting"
                )
                .to(
                    thank,
                    {
                        rotate: 0,
                        x: 0,
                        ease: textEase,
                        duration: textDur,
                        stagger: textStagger,
                    },
                    "thank"
                )
                .to(
                    thank,
                    {
                        y: 0,
                        ease: textEase,
                        duration: textDur,
                        stagger: textStagger,
                    },
                    "thank"
                )
                .addLabel("you", ">-=.23");

            tl.to(
                you,
                {
                    y: 0,
                    rotate: 0,
                    x: 0,
                    ease: textEase,
                    duration: (i) => (i > 0 ? textDur + 0.04 : textDur + 0.03),
                    stagger: textStagger,
                },
                "you"
            ).addLabel("point", ">-.3");

            tl.to(
                point,
                {
                    x: 0,
                    ease: "power1.out",
                    duration: 0.7,
                },
                "point"
            )
                .to(
                    point,
                    {
                        duration: 0.25,
                        ease: "power2.out",
                        y: "-40%",
                    },
                    "point"
                )
                .to(
                    point,
                    {
                        duration: 0.45,
                        ease: "bounce.out",
                        y: 0,
                    },
                    ">"
                );
        },
        [isMobile]
    );

    const prevSlideAnimation = useCallback(
        (slide, dir) => {
            slide.classList.remove("current");

            console.log('prevSlideAnimation ', slide, dir)

            const title = slide.querySelector(".title");
            const input = slide.querySelector(".input-container");

            const tl = globalTl.current;
            slide.classList.remove("active");

            if (
                (slide.dataset.privacy || slide.dataset.send) &&
                dir > 0 &&
                !isMobile
            ) {
                privacyDeactiveAnimation(slide, dir, title, input);
                return;
            }

            if (isMobile) {
                if (slide.dataset.info) {
                    document.body.classList.remove("dark-mode");
                }
                if (slide.dataset.privacy && dir > 0) {
                    document.body.classList.add("dark-mode");

                    tl.delay(theme.bigButtonDelay);
                }
                if (slide.dataset.send && dir > 0) {
                    document.body.classList.remove("dark-mode");
                    document.body.classList.add("final-view");
                    const points = slide.querySelectorAll(".ing-char");

                    tl.addLabel("point")
                        .to(
                            points,
                            {
                                autoAlpha: 0,
                                duration: 0.4,
                                ease: "power1.inOut",
                                stagger: -0.07,
                            },
                            "point"
                        )
                        .to(
                            points,
                            {
                                y: "100%",
                                duration: 0.25,
                                ease: "power2.in",
                                stagger: -0.07,
                            },
                            "point"
                        );
                }
            }

            tl.addLabel("prevSlideStart")
                .addLabel("start", "prevSlideStart")
                .to(
                    [title, input],
                    {
                        x: `${-dir * 130}vw`,
                        ease: disappearEase,
                        duration: disappearDur,
                        onComplete() {
                            gsap.set([title, input], {
                                autoAlpha: 0,
                            });
                        },
                    },
                    "start"
                )
                .addLabel("prevSlideEnd", ">");

            const nextLabel = isMobile ? "prevSlideEnd" : "prevSlideEnd-=.1";

            tl.addLabel("nextRefer", nextLabel).addLabel(
                "lineRefer",
                "nextRefer"
            );
        },
        [privacyDeactiveAnimation, disappearDur, disappearEase, isMobile]
    );

    const nextSlideAnimation = useCallback(
        (slide, dir, hasLine) => {

            const title = slide.querySelector(".title");
            const input = slide.querySelector(".input-container");

            slide.classList.add("active");

            const tl = globalTl.current;

            if (slide.dataset.info) {
                gsap.delayedCall(0.6, () => {
                    document.body.classList.add("dark-mode");
                })
            } else {
                document.body.classList.remove("dark-mode");
            }

            if (
                (slide.dataset.privacy || slide.dataset.send) &&
                dir < 0 &&
                !isMobile
            ) {
                privacyActiveAnimation(slide, dir, title, input);

                return;
            } else if (
                (slide.dataset.privacy || slide.dataset.send) &&
                dir > 0 &&
                !isMobile
            ) {
                const checkBtn = slide.querySelector("button[data-text]");
                const checkClone = slide.querySelector(".border-clone");

                tl.set(
                    [checkBtn, checkClone],
                    {
                        scaleX: 1,
                        clearProps: "all"
                    },
                    "setting"
                );
            }

            // if (isMobile && slide.dataset.privacy && dir < 0) {
            //     document.body.classList.remove("dark-mode");
            //     const checkBtn = slide.querySelector("button[data-text]");
            //     const checkClone = slide.querySelector(".border-clone");

            //     tl.set(
            //         [checkBtn, checkClone],
            //         {
            //             scaleX: 1,
            //             clearProps: "all",
            //         },
            //         "setting"
            //     );
            // }

            if (slide.dataset.final) {
                finalSlideAnimation(slide, dir, title, input);
                return;
            }

            const offset = isMobile ? 25 : dir > 0 ? 20 : 110;
            const opEase = "power1.inOut";
            const opDur = isMobile ? 0.4 : 0.6;

            const xEase = "power2.out";
            const xDur = isMobile ? 0.6 : dir > 0 ? 0.7 : 1.1;

            const stagger = isMobile ? 0.1 : dir * 0.15;

            const label = isMobile
                ? "nextRefer+=.1"
                : dir < 0
                ? hasLine
                    ? "nextRefer-=.4"
                    : "nextRefer-=.2"
                : "nextRefer+=.1";

            tl.addLabel("nextSlideStart", label).addLabel(
                "start",
                "nextSlideStart"
            );
            if (dir < 0 && !isMobile) {
                tl.set(
                    [title, input],
                    {
                        autoAlpha: 1,
                    },
                    "setting"
                );
            }
            if (dir > 0 || isMobile) {
                tl.fromTo(
                    [title, input],
                    {
                        autoAlpha: 0,
                    },
                    {
                        autoAlpha: 1,
                        ease: opEase,
                        duration: opDur,
                        stagger: stagger
                    },
                    "start"
                );
            }
            tl.fromTo(
                [title, input],
                {
                    x: `${dir * offset}vw`,
                },
                {
                    x: 0,
                    ease: xEase,
                    duration: xDur,
                    stagger: stagger,
                    onComplete() {
                        slide.classList.add("current");

                    },
                },
                "start"
            ).addLabel("nextSlideEnd", ">");
        },
        [privacyActiveAnimation, finalSlideAnimation, isMobile]
    );

    const lineAnimation = useCallback((dir, isLong) => {

        console.log('lineAnimation', dir, isLong)
        const tl = globalTl.current;

        const lineContainer = document.querySelector(".line-container");
        const line = lineContainer.querySelector(".line");

        const goFar = 50;

        const containerFrom =
            dir > 0
                ? `${window.innerWidth - lineContainer.clientWidth + goFar}px`
                : `${-goFar}px`;
        const containerTo =
            dir < 0
                ? `${window.innerWidth - lineContainer.clientWidth + goFar}px`
                : `${-goFar}px`;

        const lineFrom = dir > 0 ? "right" : "left";
        const lineTo = dir < 0 ? "right" : "left";

        const lineScaleInDur = 0.8;
        const lineScaleInEase = "power1.in";

        const lineScaleOutDur = 0.6;
        const lineScaleOutEase = "power1.out";

        const containerTerm = 0;
        const containerMoveDur = 1.4;
        const containerMoveEase = "power2.inOut";

        const startLabel = 0.12;

        tl.set(
            line,
            {
                transformOrigin: lineFrom,
            },
            "setting"
        )
            .addLabel("lineStart", `prevSlideStart+=${startLabel}`)
            .addLabel("start", "lineStart")
            .to(
                line,
                {
                    scaleX: 1,
                    duration: lineScaleInDur,
                    ease: lineScaleInEase,
                    delay: isLong && dir > 0 ? 0.1 : 0,
                },
                "start"
            )
            .addLabel("lineEnd", ">")
            .addLabel("end", "lineEnd")
            .fromTo(
                lineContainer,
                {
                    x: containerFrom,
                },
                {
                    x: containerTo,
                    duration: containerMoveDur,
                    ease: containerMoveEase,
                    delay: isLong && dir > 0 ? 0.1 : 0,
                },
                `start+=${containerTerm}`
            )
            .set(
                line,
                {
                    transformOrigin: lineTo,
                },
                "end"
            )
            .to(
                line,
                {
                    scaleX: 0,
                    duration: lineScaleOutDur,
                    ease: lineScaleOutEase,
                },
                "end"
            )
            .addLabel("nextRefer", "end+=.3");
    }, []);

    const afterSetting = useCallback(
        (wrapper) => {
            wrapper.removeAttribute("data-moving");

            gsap.set([".title", ".input-container"], {
                willChange: "auto",
            });

            const currentActive = wrapper.dataset.active * 1;
            const containers = wrapper.querySelectorAll(".style-container");
            const willChangeTitle = [
                containers[currentActive - 1] &&
                    containers[currentActive - 1].querySelector(".title"),
                containers[currentActive] &&
                    containers[currentActive].querySelector(".title"),
                containers[currentActive + 1] &&
                    containers[currentActive + 1].querySelector(".title"),
            ];
            const willChangeInput = [
                containers[currentActive - 1] &&
                    containers[currentActive - 1].querySelector(
                        "input-container"
                    ),
                containers[currentActive] &&
                    containers[currentActive].querySelector("input-container"),
                containers[currentActive + 1] &&
                    containers[currentActive + 1].querySelector(
                        "input-container"
                    ),
            ];

            gsap.set([willChangeTitle, willChangeInput], {
                willChange: "transform, opacity",
            });

            let delay;
            if (
                wrapper.dataset.type * 1 === 0 &&
                wrapper.dataset.active * 1 === 6 &&
                scheduleAdjust === -1
            ) {
                delay = 1.3;
            } else {
                delay = 0;
            }
            gsap.delayedCall(delay, setIsSlideAnimating, [false]);
        },
        [scheduleAdjust, setIsSlideAnimating]
    );

    const execAnimation = useCallback(
        (wrapper, slide, prevSlide, dir, isMain, hideNShow) => {
            console.log('execAnimation')
            if (slide && prevSlide) {
                closeAlert();

                const progressType = wrapper.dataset.type * 1;
                const totalStep = [6, 4][progressType];
                const currentActive = (wrapper.dataset.active - 1) * 1;

                
                const ratio = (currentActive / totalStep).toFixed(2) * 1;

                console.log({progressType,currentActive, totalStep, ratio})

                if (ratio > 1) {
                    gsap.to(".progress", {
                        autoAlpha: 0,
                        ease: "power1.inOut",
                        duration: 0.5,
                    });
                }

                const hasLine =
                    (dir > 0 && slide.dataset.line) ||
                    (dir < 0 && prevSlide.dataset.line);

                if (!isMain) {
                    globalTl.current = gsap.timeline();
                    setIsSlideAnimating(true);
                }
                const tl = globalTl.current;
                tl.addLabel("setting");
                tl.set(
                    slide,
                    {
                        display: "block",
                        onComplete() {
                            if (slide.dataset.info) {                                
                                document.querySelector(
                                    ".info-container"
                                ).scrollTop = 0;
                            }
                        },
                    },
                    "setting"
                );

                prevSlideAnimation(prevSlide, dir);
                if (hasLine && !isMobile) {
                    lineAnimation(
                        dir,
                        currentActive === 3 || currentActive - dir === 3
                    );
                }
                nextSlideAnimation(slide, dir, hasLine);

                tl.addLabel("animationEnd", "nextSlideEnd");
                if (isMain) {
                    tl.call(UIin, [], "animationEnd-=.1");
                }

                tl.call(
                    progressBarAnimation,
                    [ratio, isMain, hideNShow],
                    "animationEnd-=.1"
                );

                tl.call(afterSetting, [wrapper], "nextSlideEnd+=.1");
                tl.set(prevSlide, {
                    display: "none",
                });
            }
        },
        [
            progressBarAnimation,
            prevSlideAnimation,
            nextSlideAnimation,
            lineAnimation,
            afterSetting,
            UIin,
            isMobile,
            setIsSlideAnimating,
        ]
    );

    const animateTo = useCallback(
        (where, activeIndex, isMain) => {
            const swiperWrapper = document.querySelector(".swiper-wrapper");
            const slides = swiperWrapper.querySelectorAll(".style-container");
            const currentActive = swiperWrapper.dataset.active * 1;

            const prevSlide = slides[currentActive];
            const index = where === 0 ? activeIndex : currentActive + where;

            swiperWrapper.dataset.active = index;
            swiperWrapper.dataset.moving = true;

            setCurrentActive(index);

            const nextSlide = slides[index];

            execAnimation(
                swiperWrapper,
                nextSlide,
                prevSlide,
                where === 0 ? -1 : where,
                isMain,
                where === 0 ? true : false
            );
        },
        [execAnimation, setCurrentActive]
    );

    const mainIntroAnimation = useCallback(
        (reset, callPos) => {
            gsap.killTweensOf(window);
            setIsSlideAnimating(true);
            globalTl.current = gsap.timeline();
            const tl = globalTl.current;

            const activeIndex =
                document.querySelector(".swiper-wrapper").dataset.active * 1;

            if (activeIndex === 0) {
                if (reset) {
                    // To home animation need to be added
                    const slide =
                        document.querySelectorAll(".style-container")[callPos];

                        console.log(slide, callPos)
                    const title = slide.querySelector(".title");
                    const input = slide.querySelector(".input-container");

                    const opDur = isMobile ? 0.5 : 0.5;

                    tl.addLabel("outro");
                    tl.to(
                        [title, input],
                        {
                            autoAlpha: 0,
                            duration: opDur,
                            ease: "power1.inOut",
                            onComplete() {
                                gsap.set(".style-container", {
                                    display: "none",
                                });
                            },
                        },
                        "outro+=.1"
                    );

                    tl.to(
                        [".header-container", ".step-container", ".progress"],
                        {
                            autoAlpha: 0,
                            duration: opDur,
                            ease: "power1.inOut",
                        },
                        "outro"
                    );

                    tl.to(
                        ".progress",
                        {
                            scaleX: 0,
                            duration: opDur,
                            ease: "power2.in",
                        },
                        "outro"
                    );

                    const bg = document.querySelectorAll(".multi-chip");
                    const checkIcon =
                        document.querySelectorAll(".multi-chip .after");
                    const text = document.querySelectorAll(
                        ".multi-chip .text-wrapper span"
                    );
                    if (checkIcon.length !== 0) {
                        tl.set(checkIcon, {
                            opacity: 0,
                        })
                            .set(bg, {
                                background: "#fff",
                            })
                            .set(text, {
                                color: "#000",
                            })
                            .set(checkIcon, {
                                scale: 0.75,
                            })
                            .set([bg, text], {
                                clearProps: "all",
                            });
                    }
                }
                const header = document.querySelector(".header-container");
                const step = document.querySelector(".step-container");
                tl.set(".progress", {
                    autoAlpha: 0,
                    scaleX: 0,
                });

                if (reset) tl.call(reset, ["all"]);
                tl.set(
                    [
                        '.style-container[data-step="0"] .title-text-char',
                        '.style-container[data-step="0"] .point',
                    ],
                    {
                        clearProps: "all",
                    }
                )
                    .set('.style-container[data-step="0"] .title', {
                        autoAlpha: 1,
                        x: 0,
                        willChange: "transform",
                    })
                    .set(header, {
                        autoAlpha: 0,
                        pointerEvents: "none",
                    })
                    .set(step, {
                        autoAlpha: 0,
                    })
                    .set(".progress", {
                        autoAlpha: 1,
                    });

                tl.addLabel("setting");

                const mainWrap = document.querySelector(".main-wrap");

                tl.addLabel("maskingStart");

                // Title masking
                const titleDur = isMobile ? 0.7 : 0.9;
                const titleEase = "power2.inOut";

                const titleStagger = 0.12;

                const X = mainWrap.querySelector(".X");
                const splitedPlus = mainWrap.querySelector(
                    ".title-text-1 .title-text-line"
                );
                const splitedContact = mainWrap.querySelector(
                    ".title-text-2 .title-text-line"
                );
                const xRight = (window.innerWidth / 100) * 3;

                const { left: plusLeft, width: plusWidth } =
                    splitedPlus.getBoundingClientRect();
                const { left: xLeft, width: xWidth } =
                    X.getBoundingClientRect();

                const xValue = plusWidth - xWidth - (xLeft - plusLeft) - xRight;
                const xDist = Math.round((window.innerWidth / 100) * 3);

                const point = mainWrap.querySelector(".point");
                tl.set(
                    [
                        point,
                        splitedPlus.querySelectorAll(".title-text-char"),
                        splitedContact.querySelectorAll(".title-text-char"),
                    ],
                    {
                        autoAlpha: 1,
                        willChange: "transform",
                    }
                );

                const startLabel = reset ? ">+=.4" : ">";

                tl.addLabel("start", startLabel)
                    .fromTo(
                        splitedPlus.querySelectorAll(
                            ".title-text-char:not(.X)"
                        ),
                        {
                            x: `${xDist}px`,
                            y: "120%",
                            rotate: "14deg",
                        },
                        {
                            y: 0,
                            x: 0,
                            rotate: 0,
                            stagger: titleStagger,
                            duration: titleDur,
                            ease: titleEase,
                        },
                        "start"
                    )
                    .fromTo(
                        X,
                        {
                            x: `${xValue + xDist}px`,
                            rotate: "14deg",
                            y: "120%",
                        },
                        {
                            y: 0,
                            rotate: 0,
                            x: xValue,
                            duration: titleDur,
                            ease: titleEase,
                        },
                        "start+=.5"
                    )
                    .addLabel("xStart", ">-.15")
                    .addLabel("contactStart", "<+.5")
                    .fromTo(
                        splitedContact.querySelectorAll(".title-text-char"),
                        {
                            x: `${xDist}px`,
                            y: "120%",
                            rotate: "14deg",
                        },
                        {
                            y: 0,
                            x: 0,
                            rotate: 0,
                            stagger: 0.1,
                            duration: titleDur,
                            ease: titleEase,
                        },
                        "contactStart"
                    )
                    .addLabel("pointStart", "<+1.2");
                // Line Animation
                const lineInDur = isMobile ? 1.2 : 1.4;
                const lineInEase = "power3.inOut";

                tl.addLabel("start", "xStart").to(
                    X,
                    {
                        x: 0,
                        duration: lineInDur,
                        ease: lineInEase,
                    },
                    "start"
                );

                // Point animation
                tl.fromTo(
                    point,
                    {
                        y: "40%",
                        x: 0,
                        left: 0,
                    },
                    {
                        y: isMobile ? "-40%" : "-50%",
                        ease: "power1.out",
                        duration: isMobile ? 0.2 : 0.3,
                    },
                    "pointStart"
                )
                    .addLabel("pointDown", ">")
                    .to(
                        point,
                        {
                            y: "0",
                            ease: "bounce.out",
                            duration: isMobile ? 0.6 : 0.7,
                            onComplete() {
                                gsap.set(".title-text-char", {
                                    willChange: "auto",
                                });
                            },
                        },
                        "pointDown"
                    )
                    .addLabel("introEnd", ">");

                tl.call(animateTo, [1, 0, true], ">+=.4");
            }
        },
        [animateTo, isMobile, setIsSlideAnimating]
    );

    const next = useCallback(() => {
        console.log('next')
        animateTo(1);
    }, [animateTo]);

    const back = useCallback(() => {
        animateTo(-1);
    }, [animateTo]);

    return {
        execAnimation,
        progressBarAnimation,
        mainIntroAnimation,
        animateTo,
        next,
        back,
        nextSlideAnimation,
        prevSlideAnimation,
    };
};

export default useAnimation;
