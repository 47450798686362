import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import MultiSelectChip from "../components/Chip/MultiSelectChip";
import { duplicateOK, projectRequestData } from "../data/titleData";
import { useRecoilValue } from "recoil";
import { selectors } from "../recoil/atom";
import useFetchData from "../hooks/useFetchData";

const StyledContainer = styled.div`
    .style-wrap {
        .input-container {
            width: 100%;
        }
    }
`;

const TitleSection = ({ step, title, subText }) => (
    <div className="title">
        <div className="step">
            <span className="current">{step}</span>
            <span className="line"></span>
            <span className="total">6</span>
        </div>
        <div>{title}</div>
        <div className="sub-text">{subText}</div>
    </div>
);

const ProjectRequest = () => {
    const lang = useRecoilValue(selectors["getLanguage"]);
    const { title } = projectRequestData[lang];
    const { data } = useFetchData();
    const [category, setCategory] = useState([]);

    useEffect(() => {
        if (Array.isArray(data)) {
            setCategory(data);
        }
    }, [data]);

    return (
        <StyledContainer className="style-container" data-step="2">
            <div className="style-wrap">
                <TitleSection step="1" title={title} subText={duplicateOK[lang]} />
                <div className="input-container">
                    {category.length > 0 && (
                        <MultiSelectChip
                            selects={category}
                            coilKey="requestRangeProject"
                            startTabIndex={0}
                        />
                    )}
                </div>
            </div>
        </StyledContainer>
    );
};

export default React.memo(ProjectRequest);
