import { atom, selector } from "recoil";
import { duesTxt } from "../data/titleData";
import { getFastDateFromNow, getFastDateFromStart } from "../lib/date";

// Atoms
/**
 * Step1
 */
// Request type : 의뢰 종류
const requestType = atom({
    key: "requestType",
    default: -1,
});

/**
 * Step2
 */
// Requester Info (프로젝트, 기타의뢰 공통)
const requesterInfo = atom({
    key: "requesterInfo",
    default: {
        name: "",
        company: "",
        email: "",
        contact: "",
        department: "",
        duty: "",
    },
});

/**
 * Step3
 */
// Project name, Request name (프로젝트, 기타의뢰 공동관리)
const requestProject = atom({
    key: "requestProject",
    default: {
        projectName: "",
        requestName: "",
    },
});

/**
 * Step4
 */
// Brand range (브랜드 디자인 업무 범위 | 프로젝트 의뢰만 해당)
const requestRangeBrand = atom({
    key: "requestRangeBrand",
    default: [],
});

// UX/UI range (UX/UI 디자인 업무 범위 | 프로젝트 의뢰만 해당)
const requestRangeUI = atom({
    key: "requestRangeUI",
    default: [],
});

// Kind range (의뢰 업무 종류 | 기타 의뢰만 해당)
const requestRangeKind = atom({
    key: "requestRangeKind",
    default: -1,
});

// 의뢰 디테일
const kindEtcDetail = atom({
    key: "kindEtcDetail",
    default: "",
});

/**
 * Step5 (프로젝트 의뢰만)
 */
// 프로젝트 의뢰 세부사항 (프로젝트 의뢰만 해당)
const requestRangeProject = atom({
    key: "requestRangeProject",
    default: [],
});

const requestRangeProjectEtc = atom({
    key: "requestRangeProjectEtc",
    default: "",
});
/**
 * Step6 (프로젝트 의뢰만)
 */
// 예상 시작일

const [startYear, startMonth, startDueType] = getFastDateFromNow();
const [endYear, endMonth, endDueType] = getFastDateFromStart();

const startDue = atom({
    key: "startDue",
    default: {
        year: startYear,
        month: startMonth,
        due: startDueType,
        dues: duesTxt,
    },
});

// 예상 종료일
const endDue = atom({
    key: "endDue",
    default: {
        year: endYear,
        month: endMonth,
        due: endDueType,
        dues: duesTxt,
    },
});

// 조율 가능 여부
const scheduleAdjust = atom({
    key: "scheduleAdjus",
    default: -1,
});

/**
 * Step7 (프로젝트 의뢰만)
 */
// 예상 예산
const requestPrice = atom({
    key: "requestPrice",
    default: -1,
});

/**
 * Step8
 */
// 추가 정보
const additionalInfo = atom({
    key: "additionalInfo",
    default: {
        text: "",
        file: [],
    },
});

const privacyCheck = atom({
    key: "privacyCheck",
    default: false,
});

const mobileWidth = atom({
    key: "isMobile",
    default: window.innerWidth <= 1055,
});

const language = atom({
    key: "language",
    default: "kr",
});

const currentActive = atom({
    key: "currentActive",
    default: 0,
});

// Post 관련 전역 상태
const apiLoading = atom({
    key: "apiLoading",
    default: false,
});

const apiError = atom({
    key: "apiError",
    default: false,
});

const apiSuccess = atom({
    key: "apiSuccess",
    default: false,
});

// Animating 전역 상태
const isSlideAnimating = atom({
    key: "isSlideAnimating",
    default: false,
});

// Next disable
const nextDisabled = atom({
    key: "nextDisabled",
    default: true,
});

export const atoms = {
    requestType,
    requestPrice,
    requestRangeBrand,
    requestRangeUI,
    requestRangeKind,
    requesterInfo,
    requestProject,
    kindEtcDetail,
    requestRangeProject,
    requestRangeProjectEtc,
    startDue,
    endDue,
    scheduleAdjust,
    additionalInfo,
    privacyCheck,
    language,
    currentActive,
    mobileWidth,
    apiLoading,
    apiError,
    apiSuccess,
    isSlideAnimating,
    nextDisabled,
};

// Selectors
const createSelector = (key) => {
    return ({ get }) => {
        const res = get(key);
        return res;
    };
};

/**
 * Step1
 */
// Request type : 의뢰 종류
const getRequestType = selector({
    key: "getRequestType",
    get: createSelector(requestType),
});

/**
 * Step2
 */
// Requester Info (프로젝트, 기타의뢰 공통)
const getRequesterInfo = selector({
    key: "getRequesterInfo",
    get: createSelector(requesterInfo),
});

/**
 * Step3
 */
// Project name, Request name (프로젝트, 기타의뢰 공동관리)
const getRequestProject = selector({
    key: "getRequestProject",
    get: createSelector(requestProject),
});
const getRequestRangeProjectEtc = selector({
    key: "getRequestRangeProjectEtc",
    get: createSelector(requestRangeProjectEtc),
});

/**
 * Step4
 */
// Brand range (브랜드 디자인 업무 범위 | 프로젝트 의뢰만 해당)
const getRequestRangeBrand = selector({
    key: "getRequestRangeBrand",
    get: createSelector(requestRangeBrand),
});

// UX/UI range (UX/UI 디자인 업무 범위 | 프로젝트 의뢰만 해당)
const getRequestRangeUI = selector({
    key: "getRequestRangeUI",
    get: createSelector(requestRangeUI),
});

// Kind range (의뢰 업무 종류 | 기타 의뢰만 해당)
const getRequestRangeKind = selector({
    key: "getRequestRangeKind",
    get: createSelector(requestRangeKind),
});

// Kind etc detail, 의뢰 기타 세부설명
const getKindEtcDetail = selector({
    key: "getKindEtcDetail",
    get: createSelector(kindEtcDetail),
});

/**
 * Step5 (프로젝트 의뢰만)
 */
// 프로젝트 의뢰 세부사항 (프로젝트 의뢰만 해당)
const getRequestRangeProject = selector({
    key: "getRequestRangeProject",
    get: createSelector(requestRangeProject),
});

/**
 * Step6 (프로젝트 의뢰만)
 */
// 예상 시작일
const getStartDue = selector({
    key: "getStartDue",
    get: createSelector(startDue),
});

// 예상 종료일
const getEndDue = selector({
    key: "getEndDue",
    get: createSelector(endDue),
});

// 조율 가능 여부
const getScheduleAdjust = selector({
    key: "getScheduleAdjust",
    get: createSelector(scheduleAdjust),
});

/**
 * Step7 (프로젝트 의뢰만)
 */
// 예상 예산
const getRequestPrice = selector({
    key: "getRequestPrice",
    get: createSelector(requestPrice),
});

/**
 * Step8 추가 정보
 */
// 추가 정보
const getAdditionalInfo = selector({
    key: "getAdditionalInfo",
    get: createSelector(additionalInfo),
});

const getPrivacyCheck = selector({
    key: "getPrivacyCheck",
    get: createSelector(privacyCheck),
});

const getLanguage = selector({
    key: "getLanguage",
    get: createSelector(language),
});

const getCurrentActive = selector({
    key: "getCurrentActive",
    get: createSelector(currentActive),
});

const getIsMobileWidth = selector({
    key: "getIsMobileWidth",
    get: createSelector(mobileWidth),
});

export const formatMonth = (month) => {
    if (month < 10) {
        return `0${month}`;
    } else {
        return month;
    }
};

const getApiLoading = selector({
    key: "getApiLoading",
    get: createSelector(apiLoading),
});
const getApiError = selector({
    key: "getApiError",
    get: createSelector(apiError),
});
const getApiSuccess = selector({
    key: "getApiSuccess",
    get: createSelector(apiSuccess),
});

const getIsSlideAnimating = selector({
    key: "getIsSlideAnimating",
    get: createSelector(isSlideAnimating),
});

const getNextDisabled = selector({
    key: "getNextDisabled",
    get: createSelector(nextDisabled),
});

export const selectors = {
    getRequestType,
    getRequesterInfo,
    getRequestProject,
    getRequestRangeProjectEtc,
    getRequestRangeBrand,
    getRequestRangeUI,
    getRequestRangeKind,
    getRequestRangeProject,
    getStartDue,
    getEndDue,
    getScheduleAdjust,
    getRequestPrice,
    getAdditionalInfo,
    getKindEtcDetail,
    getPrivacyCheck,
    getLanguage,
    getCurrentActive,
    getIsMobileWidth,
    getApiLoading,
    getApiError,
    getApiSuccess,
    getIsSlideAnimating,
    getNextDisabled,
};
