import styled from "@emotion/styled";
import React from "react";
import { useRecoilValue } from "recoil";
import { selectors } from "../../recoil/atom";
import {
    rangeBrandData,
    rangeKindData,
    rangeProjectData,
    rangeUIData,
    requestPriceData,
    typeData,
} from "../../data/formData";
import { checkInformationData } from "../../data/titleData";

const mobileInfoFontSize = 14;

const StyledContainer = styled.div`
    height: 100%;
    max-width: 768px;

    overflow: auto;

    @media screen and (max-width: 1055px) {
        max-width: unset;
    }

    padding-right: 30px;

    &::-webkit-scrollbar {
        width: 10px;
        background: transparent;

        @media screen and (max-width: 1055px) {
            width: 6px;
        }
    }

    &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 6px;
        margin-right: 60px;
    }

    &::-webkit-scrollbar-track {
        margin-right: 60px;
    }

    &:not(:has(.active)) {
        &::-webkit-scrollbar {
            display: none 
        }
    }
    .info {
        margin-bottom: 55px;
        @media screen and (max-width: 1055px) {
            margin-bottom: 25px;
            font-size: ${mobileInfoFontSize}px;
        }
        .info-title {
            font-weight: 700;
            color: #fff;
            background: #000;

            letter-spacing: -0.03em;
            line-height: 22px;

            border-radius: 22px;

            padding: 2px 11px;

            display: inline-block;

            margin-bottom: 26px;

            @media screen and (max-width: 1055px) {
                margin-bottom: 10px;
                font-size: ${mobileInfoFontSize}px;
            }
        }
        .info-body {
            word-break: break-word;

            & ::selection {
                color: #f0f1f4;
                background: #000;
            }

            .requester-info {
                letter-spacing: -0.03em;
                margin-bottom: 40px;

                @media screen and (max-width: 1055px) {
                    margin-bottom: 20px;
                }

                .requester-title {
                    color: #999;
                    line-height: 22px;

                    margin-bottom: 10px;

                    white-space: nowrap;
                    @media screen and (max-width: 1055px) {
                        margin-bottom: 8px;
                        font-size: ${mobileInfoFontSize}px;
                        line-height: 1.1;
                    }
                }

                .requester-content {
                    line-height: 24px;
                    @media screen and (max-width: 1055px) {
                        font-size: ${mobileInfoFontSize}px;
                        line-height: 1.2;
                    }
                }
            }
            .requester-info--requester {
                display: flex;
                width: 90%;

                @media screen and (max-width: 768px) {
                    flex-direction: column;
                    width: 100%;
                }

                .requester-info {
                    padding: 0 35px;
                    flex: 1;

                    border-right: 1px solid #ccc;

                    &:nth-of-type(1) {
                        padding-left: 0;
                    }
                    &:nth-last-of-type(1) {
                        padding-right: 0;
                        border: none;
                    }

                    @media screen and (max-width: 768px) {
                        padding: 0;
                        border: none;
                    }
                }
            }

            .hashtag-container {
                width: 50%;

                .hashtag {
                    display: inline-block;
                    white-space: nowrap;

                    padding-right: 10px;

                    &:nth-last-of-type(1) {
                        padding-right: 0;
                    }
                }
            }

            .info-file {
                font-size: 18px;
                line-height: 1.5;
                letter-spacing: -0.03em;

                margin: 3px;

                position: relative;

                padding-left: calc(1.3em + 9px);
                padding-right: calc(1.4em + 15px);
                @media screen and (max-width: 1055px) {
                    font-size: 13px;
                }
                &::before {
                    content: "";

                    position: absolute;
                    left: 0;
                    top: 0.2em;

                    transform: translate(0, 0);

                    width: 1.3em;
                    height: 1.3em;

                    background: url("/svg/file-upload.svg");

                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
        }
    }
`;

const InfoProject = ({ name }) => {
    const lang = useRecoilValue(selectors["getLanguage"]);

    const {
        projectName,
        projectRange: projectRangeText,
        projectDue,
        projectPrice,
        projectDueContent,
    } = checkInformationData[lang];

    const brandRange = useRecoilValue(selectors["getRequestRangeBrand"]);
    const UIRange = useRecoilValue(selectors["getRequestRangeUI"]);
    const projectRange = useRecoilValue(selectors["getRequestRangeProject"]);
    
    const price = useRecoilValue(selectors["getRequestPrice"]);

    const startDue = useRecoilValue(selectors["getStartDue"]);
    const endDue = useRecoilValue(selectors["getEndDue"]);
    const projectRangeEtc = useRecoilValue(selectors["getRequestRangeProjectEtc"]);
    console.log(projectRange)

    return (
        <>
            <div className="info">
                <div className="info-title">{projectName}</div>
                <div className="info-body">{name}</div>
            </div>
            <div className="info">
                <div className="info-title">{projectRangeText}</div>
                <div className="info-body">
                    <div className="hashtag-container">
                        {projectRange.map(p => {
                            return (
                                <span key={p.id} className="hashtag">
                                    #{p.name} {p.name === '기타' && projectRangeEtc !== '' ? (`(${projectRangeEtc})`) : ''}
                                </span>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="info">
                <div className="info-title">{projectDue}</div>
                <div className="info-body">
                    {projectDueContent(
                        startDue.year,
                        startDue.month,
                        startDue.dues[lang][startDue.due],
                        endDue.year,
                        endDue.month,
                        endDue.dues[lang][endDue.due]
                    )}
                </div>
            </div>
            <div className="info">
                <div className="info-title">{projectPrice}</div>
                <div className="info-body">
                    {price !== -1 && requestPriceData[price].text[lang]}
                </div>
            </div>
        </>
    );
};

const InfoRequest = ({ name }) => {
    const lang = useRecoilValue(selectors["getLanguage"]);
    const kindDetail = useRecoilValue(selectors["getKindEtcDetail"]);
    const { requestName, requestKind: requestKindText } =
        checkInformationData[lang];
    const requestKind = useRecoilValue(selectors["getRequestRangeKind"]);

    return (
        <>
            <div className="info">
                <div className="info-title">{requestName}</div>
                <div className="info-body">{name}</div>
            </div>
            <div className="info">
                <div className="info-title">{requestKindText}</div>
                <div className="info-body">
                    <div className="hashtag-container">
                        {requestKind !== -1 && (
                            <span className="hashtag">
                                #{rangeKindData[requestKind].text[lang]} {kindDetail !== '' ? (`(${kindDetail})`) : ''}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

const Info = () => {
    const lang = useRecoilValue(selectors["getLanguage"]);

    const {
        requestType,
        requester,
        name,
        company,
        department,
        duty,
        email,
        contact,
        additionalDescription,
        attached,
    } = checkInformationData[lang];

    const reqType = useRecoilValue(selectors["getRequestType"]);
    const requesterInfo = useRecoilValue(selectors["getRequesterInfo"]);
    const requestName = useRecoilValue(selectors["getRequestProject"]);
    const additionalInfo = useRecoilValue(selectors["getAdditionalInfo"]);

    return (
        <StyledContainer className="info-container">
            <div className="info-wrap">
                <div className="info">
                    <div className="info-title">{requestType}</div>
                    <div className="info-body">
                        {reqType !== -1 && typeData[reqType].text[lang]}
                    </div>
                </div>
                <div className="info">
                    <div className="info-title">{requester}</div>
                    <div className="info-body">
                        <div className="requester-info--requester">
                            <div className="requester-info">
                                <div className="requester-title">{name}</div>
                                <div className="requester-content">
                                    {requesterInfo.name}
                                </div>
                            </div>
                            <div className="requester-info">
                                <div className="requester-title">
                                    {department}
                                </div>
                                <div className="requester-content">
                                    {requesterInfo.department === ""
                                        ? "-"
                                        : requesterInfo.department}
                                </div>
                            </div>
                            <div className="requester-info">
                                <div className="requester-title">{duty}</div>
                                <div className="requester-content">
                                    {requesterInfo.duty === ""
                                        ? "-"
                                        : requesterInfo.duty}
                                </div>
                            </div>
                        </div>
                        <div className="requester-info">
                            <div className="requester-title">{company}</div>
                            <div className="requester-content">
                                {requesterInfo.company}
                            </div>
                        </div>
                        <div className="requester-info">
                            <div className="requester-title">{email}</div>
                            <div className="requester-content">
                                {requesterInfo.email}
                            </div>
                        </div>
                        <div className="requester-info">
                            <div className="requester-title">{contact}</div>
                            <div className="requester-content">
                                {requesterInfo.contact}
                            </div>
                        </div>
                    </div>
                </div>
                {reqType !== "etc" ? (
                    <InfoProject name={requestName.projectName} />
                ) : (
                    <InfoRequest name={requestName.requestName} />
                )}
                <div className="info">
                    <div className="info-title">{additionalDescription}</div>
                    <div className="info-body">
                        {additionalInfo.text === ""
                            ? "-"
                            : additionalInfo.text.split("\n").map((line, i) => (
                                  <span key={i}>
                                      {line}
                                      <br />
                                  </span>
                              ))}
                    </div>
                </div>
                <div className="info">
                    <div className="info-title">{attached}</div>
                    <div className="info-body">
                        {additionalInfo.file.length === 0
                            ? "-"
                            : additionalInfo.file.map((f) => (
                                  <div key={f.name} className="info-file">
                                      {f.name}
                                  </div>
                              ))}
                    </div>
                </div>
            </div>
        </StyledContainer>
    );
};

export default React.memo(Info);
