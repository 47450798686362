import { useCallback } from "react";
import { useResetRecoilState } from "recoil";
import { atoms } from "../recoil/atom";
import useAnimation from "./useAnimation";
import usePost from "./usePost";

const useReset = () => {
    const { mainIntroAnimation } = useAnimation();
    const { resetSuccess } = usePost();

    const resetRequestType = useResetRecoilState(atoms["requestType"]);
    const resetRequester = useResetRecoilState(atoms["requesterInfo"]);
    const resetRequestName = useResetRecoilState(atoms["requestProject"]);
    const resetBrandRange = useResetRecoilState(atoms["requestRangeBrand"]);
    const resetUIRange = useResetRecoilState(atoms["requestRangeUI"]);
    const resetProjectRange = useResetRecoilState(atoms["requestRangeProject"]);
    const resetKindRange = useResetRecoilState(atoms["requestRangeKind"]);
    const resetStartDue = useResetRecoilState(atoms["startDue"]);
    const resetEndDue = useResetRecoilState(atoms["endDue"]);
    const resetPrice = useResetRecoilState(atoms["requestPrice"]);
    const resetAdditionalInfo = useResetRecoilState(atoms["additionalInfo"]);
    const resetAdjust = useResetRecoilState(atoms["scheduleAdjust"]);
    const resetPrivacy = useResetRecoilState(atoms["privacyCheck"]);
    const resetRequestDetail = useResetRecoilState(atoms["kindEtcDetail"]);
    const resetApiLoading = useResetRecoilState(atoms["apiLoading"]);
    const resetApiError = useResetRecoilState(atoms["apiError"]);
    const resetApiSuccess = useResetRecoilState(atoms["apiSuccess"]);

    const resetAll = useCallback(
        (resetType) => {
            if (resetType === "all") {
                resetRequestType();
                resetRequester();
                resetApiLoading();
                resetApiError();
                resetApiSuccess();
            }
            if (resetType === "all" || resetType === "etc") {
                resetRequestName();
                resetBrandRange();
                resetUIRange();
                resetProjectRange();
                resetStartDue();
                resetEndDue();
                resetAdjust();
                resetPrice();
            }

            if (resetType === "all" || resetType === "project") {
                resetRequestName();
                resetKindRange();
                resetRequestDetail();
            }

            resetAdditionalInfo();
            resetPrivacy();
            resetSuccess();

            const errors = [
                ...document.querySelectorAll(".error"),
                ...document.querySelectorAll(".error-with-text"),
            ];

            if (errors.length !== 0) {
                for (const error of errors) {
                    error.classList.remove("error");
                    error.classList.remove("error-with-text");
                }
            }
        },
        [
            resetRequestType,
            resetRequester,
            resetRequestName,
            resetBrandRange,
            resetUIRange,
            resetProjectRange,
            resetKindRange,
            resetStartDue,
            resetEndDue,
            resetPrice,
            resetAdditionalInfo,
            resetAdjust,
            resetPrivacy,
            resetRequestDetail,
            resetSuccess,
            resetApiError,
            resetApiLoading,
            resetApiSuccess,
        ]
    );

    const toHome = (index) => {
        const active = document.querySelector(".style-container.active");
        const current = document.querySelector(".style-container.current");

        if (active) active.classList.remove("active");
        if (current) current.classList.remove("current");
        const swiperWrapper = document.querySelector(".swiper-wrapper");
        const callPos = index ?? swiperWrapper.dataset.active * 1;
        swiperWrapper.dataset.active = 0;

        window.onbeforeunload = null;

        document.body.classList.remove("dark-mode");
        mainIntroAnimation(resetAll, callPos);
    };

    return { toHome, resetAll };
};

export default useReset;
