import React, { useCallback } from "react";
import styled from "@emotion/styled";
import Input from "../components/Input";
import { requesterData, requestNameData } from "../data/titleData";
import { useRecoilValue } from "recoil";
import { PhoneNumberUtil } from "google-libphonenumber";
import { selectors } from "../recoil/atom";
import CheckPrivacyView from "./CheckPrivacyView";

const StyledMainContainer = styled.div`
    .style-wrap {
        padding-right: 0;
        justify-content: space-between;

        @media screen and (max-width: 1055px) {
            justify-content: flex-start;
            padding-right: 25px;
        }

        .input-container {
            @media screen and (max-width: 1055px) {
                align-self: flex-start;
                width: calc(100% + 25px);
            }
        }
    }
`;
const RequesterView = ({ type }) => {
    const isMobile = useRecoilValue(selectors["getIsMobileWidth"]);
    const lang = useRecoilValue(selectors["getLanguage"]);
    const {
        title,
        namePlaceholder,
        companyPlaceholder,
        projectNamePlaceholder,
        emailPlaceholder,
        contactPlaceholder,
        departmentPlaceholder,
        dutyPlaceholder,
        emailErrorText,
        companyErrorText,
        projectNameErrorText,
        contactErrorText,
    } = requesterData[lang];

    const onBlur = useCallback((input) => {
        if (input.value.trim() === "") {
            input.classList.add("error");
        }
    }, []);

    const onBlurEmail = useCallback(
        (input) => {
            const emailReg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

            if (input.value.trim() === "") {
                input.classList.add("error");
                input.classList.remove("error-with-text");
            } else {
                if (!emailReg.test(input.value)) {
                    if (!isMobile) {
                        document.querySelector(
                            "input[name='email'] ~ .with-text .error-text"
                        ).innerHTML = emailErrorText;
                    }

                    input.classList.add("error-with-text");
                }
            }
        },
        [emailErrorText, isMobile]
    );

    const onBlurContact = useCallback((input) => {
        const phoneUtil = PhoneNumberUtil.getInstance();

        if (input.value.trim() === "") {
            input.classList.add("error");
            input.classList.remove("error-with-text");
        } else {
            try {
                const phone = phoneUtil.parseAndKeepRawInput(input.value, "kr");
                const valid = phoneUtil.isValidNumberForRegion(phone, "kr");
                if (!valid) {
                    input.classList.add("error-with-text");
                }
            } catch (e) {
                input.classList.add("error-with-text");
            }
        }
    }, []);

    return (
        <>
            <StyledMainContainer
                className="style-container"
                data-step="5"
                data-line
            >
                <div className="style-wrap">
                    <div className="title">
                        <div className="step">
                            <span className="current">{type === 0 ? 4 : 2}</span>
                            <span className="line"></span>
                            <span className="total">{type === 0 ? 6 : 4}</span>
                        </div>
                        <div>{title}</div>
                    </div>
                    <div className="input-container">
                        <Input
                            placeholder={namePlaceholder}
                            coilKey="requesterInfo"
                            name="name"
                            type="text"
                            data-tabindex="1"
                            onBlur={onBlur}
                        />
                        <Input
                            placeholder={companyPlaceholder}
                            coilKey="requesterInfo"
                            name="company"
                            data-tabindex="2"
                            type="text"
                            errorText={companyErrorText}
                            onBlur={onBlur}
                        />
                        <Input
                            placeholder={type === 0 ? "프로젝트명" : "의뢰명"}
                            coilKey="requestProject"
                            name={type === 0 ? "projectName" : "requestName"}
                            data-tabindex="3"
                            type="text"
                            errorText={projectNameErrorText}
                            onBlur={onBlur}
                        />
                        <Input
                            placeholder={emailPlaceholder}
                            coilKey="requesterInfo"
                            name="email"
                            data-tabindex="4"
                            errorText={emailErrorText}
                            inputMode="email"
                            autoCapitalize="off"
                            type="text"
                            onBlur={onBlurEmail}
                        />
                        <Input
                            placeholder={contactPlaceholder}
                            coilKey="requesterInfo"
                            name="contact"
                            data-tabindex="5"
                            inputMode="tel"
                            type="text"
                            data-type="number"
                            errorText={contactErrorText}
                            onBlur={onBlurContact}
                        />
                        <Input
                            placeholder={departmentPlaceholder}
                            coilKey="requesterInfo"
                            type="text"
                            name="department"
                            data-tabindex="6"
                        />
                        <Input
                            placeholder={dutyPlaceholder}
                            coilKey="requesterInfo"
                            type="text"
                            name="duty"
                            data-tabindex="7"
                        />
                        <CheckPrivacyView />
                    </div>
                </div>

            </StyledMainContainer>
        </>
    );
};

export default React.memo(RequesterView);
