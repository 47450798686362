import React from "react";
import styled from "@emotion/styled";
import { rangeKindData } from "../data/formData";
import ChoiseChip from "../components/Chip/ChoiseChip";
import { useRecoilValue } from "recoil";
import { selectors } from "../recoil/atom";
import NormalInput from "../components/Input/NormalInput";
import classNames from "classnames";
import { requestKindData } from "../data/titleData";

const StyledContainer = styled.div`
    .style-wrap {
        .input-container {
            width: 100%;
            position: relative;

            .chip-container {
                justify-content: flex-start;
            }
        }
    }
`;

const RequestKindView = () => {
    const lang = useRecoilValue(selectors["getLanguage"]);
    const kindRange = useRecoilValue(selectors["getRequestRangeKind"]);
    const { title, etcRequestPlaceholder } = requestKindData[lang];

    return (
        <>
            <StyledContainer
                className="style-container"
                data-step="1"
                data-line
            >
                <div className="style-wrap">
                    <div className="title">
                        <div className="step">
                            <span className="current">1</span>
                            <span className="line"></span>
                            <span className="total">4</span>
                        </div>
                        <div>해당 의뢰의<br />업무 종류는<br />무엇인가요?</div>
                    </div>
                    <div className="input-container">
                        <ChoiseChip
                            choises={rangeKindData}
                            coilKey="requestRangeKind"
                        />

                        <div
                            className={classNames(
                                "etc-detail",
                                kindRange === "etc" && "show"
                            )}
                        >
                            <NormalInput
                                placeholder={etcRequestPlaceholder}
                                type="text"
                                coilKey="kindEtcDetail"
                            />
                        </div>
                    </div>
                </div>
            </StyledContainer>
        </>
    );
};

export default React.memo(RequestKindView);
